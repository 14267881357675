import React from 'react'

const AppTest: React.FunctionComponent = () => {
  // This component is intended for testing out markup during development,
  // especially for building email templates.

  const placeholder = 'Placeholder'
  const placeholderArray = ['placeholder1', 'placeholder2']

  const techsArray = [
    'Ion Exchange',
    'Constructed Wetland Treatment System (anaerobic)',
  ]

  const classesArray = [
    'Semi-Passive or Active',
    'Biogeochemical Anaerobic',
    'Transformation',
  ]

  let placeholders = ''
  placeholders = placeholderArray.reduce((output, currentPlaceholder) => {
    return output + `
      <table>
        <tbody>
          <tr>
            <td style="padding-right: 7px;" width="29">
              <img src="${placeholder}" width="22" height="22" />
            </td>
            <td>
              ${currentPlaceholder}
            </td>
          </tr>
        </tbody>
      </table>
    `
  }, '')

  const placeholderNum = 2

  const techs = techsArray.reduce((output, tech) => {
    return output + `
      <table>
        <tbody>
          <tr>
            <td style="padding-right: 7px;" width="29">
              <img src="${placeholder}" width="22" height="22" />
            </td>
            <td style="font-size: 18px;">
              <b style="font-weight: 600;">${tech}</b>
            </td>
          </tr>
          <tr>
            <td style="font-size: 12px; padding: 3px 3px 10px 0;" colspan="2">
              <span
                style="background-color: rgba(254, 254, 254, 0.4);
                  border-radius: 4px;
                  display: inline-block;
                  padding: 1px 8px;
                  margin-right: 3px;"
              >
                ${classesArray[0]}
              </span>
              <span
                style="background-color: rgba(254, 254, 254, 0.3);
                  border-radius: 4px;
                  display: inline-block;
                  padding: 1px 8px;
                  margin-right: 3px;"
              >
                ${classesArray[1]}
              </span>
              <span
                style="background-color: rgba(254, 254, 254, 0.2);
                  border-radius: 4px;
                  display: inline-block;
                  padding: 1px 8px;
                  margin-right: 3px;"
              >
                ${classesArray[2]}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    `
  }, '')

  function createMarkup (): { __html: string } {
    return {
      __html: `
        <style>
          .btn-link:hover {
            background-color: #0d80cc !important;
          }
        </style>
        <table
          width="100%"
          height="100%"
          align="center"
          cellpadding="25"
          style="color: #282c2f; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;"
        >
          <tbody>
            <tr>
              <td></td>
              <td width="624">
                <table
                  cellpadding="12"
                >
                  <tbody>
                    <tr>
                      <td>
                        <a href="${placeholder}">
                          <img src="${placeholder}" width="250" height="67" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="background-color: #2b3f69; border-radius: 10px; color: white;">
                          <table>
                            <tbody>
                              <tr>
                                <td style="padding: 5px 15px 0 15px;">
                                  <div style="font-size: 22px;"><b style="font-weight: 600;">Treating ${placeholder}</b></div>
                                </td>
                              </tr>
                              <tr>
                                <td style="padding: 5px 15px 8px 15px;">
                                  ${placeholders}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="background-color: #2b3f69; border-radius: 10px; color: white;">
                          <table>
                            <tbody>
                              <tr>
                                <td style="padding: 5px 15px 6px 15px;">
                                  <div style="font-size: 22px;"><b style="font-weight: 600;">Potential Technologies:</b></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="background-color: #0c77be; border-radius: 10px; color: white;">
                          <table>
                            <tbody>
                              <tr>
                                <td style="padding: 8px 15px 6px 15px;">
                                  ${techs}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="border: 1px solid rgba(12, 119, 190, 0.2); border-radius: 10px;">
                          <table>
                            <tbody>
                              <tr>
                                <td style="font-size: 14px; padding: 8px 15px 0 15px;">
                                  View more details about your result, including the answers you gave to determine the result, descriptions of each potential technology, and
                                  ${placeholderNum} additional notes on things to consider.
                                </td>
                              </tr>
                              <tr>
                                <td style="padding: 10px 15px 10px 15px;">
                                  <a
                                    class="btn-link"
                                    href="${placeholder}"
                                    style="display: inline-block;
                                      background-color: #0c77be;
                                      padding: 5px 15px;
                                      border-radius: 4px;
                                      border: 1px solid #0c77be;
                                      color: white !important;
                                      text-decoration: none;
                                      text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
                                      box-shadow: 0 2px 0 rgb(0 0 0 / 5%);"
                                  >
                                    <b style="font-weight: 500;">View Result on mScout</b>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td style="font-size: 14px; padding: 0 15px 8px 15px;">
                                  This result was calculated with mScout version ${placeholder}.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 8px 15px 0 15px;">
                        <p style="font-size: 12px;">
                          mScout is brought to you by Maven Water & Environment. <br />
                          <a href="${placeholder}">Visit mScout</a> •
                          <a href="https://www.mavenwe.com">Visit Maven Water & Environment</a> •
                          <a href="mailto:mscoutweb@mavenwe.com">mscoutweb@mavenwe.com</a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      `,
    }
  }

  return (
    <div dangerouslySetInnerHTML={createMarkup()} />
  )
}

export default AppTest
