// import * as t from 'io-ts'
import * as tPromise from 'io-ts-promise'
import { types, getEnv, cast, flow } from 'mobx-state-tree'
import { analytics, functions, firebase } from '../firebase'
import cloneDeep from 'lodash/cloneDeep'

import { MIAppState, MDisplayResult, AlertActionKey } from '../types'
import {
  IAnsweredQuestion,
  IContactFormEmail,
  IFinalAnswers,
  IResult,
  IShareFormDetails,
  IStoredResultRequest,
  IUserData,
  MQuestion,
  MResult,
  MUserData,
  Result,
  FinalAnswers,
} from '../../shared/types'
import initialQuestions from '../../shared/initialQuestions'
import { IRootStoreEnv } from './index'
import config, { Stage } from '../config'
import { buildDisplayResult } from './displayResult'
import { prepForApi } from '../../shared/utils'

const AppStore = types
  .model('AppStore', {
    appState: types.optional(MIAppState, {}),
    displayResult: types.optional(types.maybeNull(MDisplayResult), null),
    questions: types.optional(types.array(MQuestion), initialQuestions),
    result: types.optional(types.maybeNull(MResult), null),
    userData: types.optional(MUserData, {}),
  })
  .views(self => ({
    get currentQId (): number {
      const env = getEnv<IRootStoreEnv>(self)
      return env.router.currentQId
    },
    get prevQId (): number | false {
      const qId = this.currentQId
      return qId - 1 >= 0 && qId - 1
    },
    get nextQId (): number | false {
      const qId = this.currentQId
      return qId + 1 <= this.maxQId && qId + 1
    },
    get allPreviousQuestionsAnswered (): boolean {
      const qId = this.currentQId
      for (let i = 0; i < qId; i++) {
        if (self.questions[i].selectedAnswer === null) {
          return false
        }
      }
      return true
    },
    get noUpcomingQuestionsAnswered (): boolean {
      const qId = this.currentQId
      for (let i = this.maxQId; i > qId; i--) {
        if (self.questions[i].selectedAnswer !== null) {
          return false
        }
      }
      return true
    },
    get questionsAnswered (): number {
      let answered = 0
      self.questions.forEach((question) => {
        if (question.selectedAnswer !== null) { answered++ }
      })
      return answered
    },
    get questionCount (): number {
      return self.questions.length
    },
    get maxQId (): number {
      return this.questionCount - 1
    },
    get allQuestionsAnswered (): boolean {
      for (let i = 0; i <= this.maxQId; i++) {
        if (self.questions[i].selectedAnswer === null) {
          return false
        }
      }
      return true
    },
    get nonCOPCQuestionHasAnswerComments (): boolean {
      const answeredQuestionsWithComments =
        self.result?.answeredQuestionsWithComments
      if (answeredQuestionsWithComments === undefined) {
        throw new Error('Unable to find answer comment data.')
      }
      for (const question of answeredQuestionsWithComments) {
        if (
          question.qId !== 0 &&
          question.answerComments !== null
        ) {
          // qId 0 is COPC
          return true
        }
      }
      return false
    },
    get mScoutVersion (): string {
      let version = process.env.REACT_APP_VERSION ?? 'Unknown'
      const suffix = {
        [Stage.DevEmulated]: '-dev',
        [Stage.Dev]: '-dev',
        [Stage.Staging]: '-beta',
        [Stage.Prod]: '',
      }
      version += suffix[config.stage]
      return version
    },
  }))
  .actions(self => {
    const setContactFormOpen = (open: boolean): void => {
      self.appState.contactFormOpen = open
    }

    const setEmailResultModalOpen = (open: boolean): void => {
      self.appState.emailResultModalOpen = open
    }

    const setSkipResultEmail = (skip: boolean): void => {
      self.appState.skipResultEmail = skip
    }

    const setUserData = (userData: IUserData): void => {
      self.userData = cast(userData)
    }

    const markInitComplete = (): void => {
      self.appState.init = true
    }

    const revisitResult = flow(function * (
      resultId?: string,
      secretId?: string,
    ) {
      if (resultId === undefined || secretId === undefined) {
        throw new Error('Unable to load solutions. Please check the URL.')
      }
      setLoading(true)
      self.appState.revisitResultMode = true

      const getStoredResult = functions.httpsCallable('getStoredResult')
      const storedResultRequest: IStoredResultRequest = {
        resultId,
        secretId,
      }

      try {
        const response: firebase.functions.HttpsCallableResult =
          yield getStoredResult(storedResultRequest)
        const result: IResult = yield tPromise.decode(Result, response.data)
        setResult(result)
        setLoading(false)
        markInitComplete()
      } catch (uncastError) {
        const error = uncastError as Error & firebase.functions.HttpsError
        let customMessage = null
        if (error.code === 'invalid-argument') {
          customMessage = 'The data sent to the server was in the wrong format, or the data fetched from the server was in the wrong format.'
        } else if (error.code === 'not-found') {
          customMessage = 'We were unable to retrieve your results. Please double check the URL.'
        } else if (error.code === 'internal') {
          customMessage = 'An unexpected error occured. There may be a server issue, or an issue with your internet connection.'
          if (error.message !== undefined) {
            customMessage += `\n\nAdditional Error Info: ${error.message}`
          }
        } else if (tPromise.isDecodeError(error)) {
          customMessage = ('The data received from the server was in the wrong format. Your browser may have an older version of the web app cached. Please refresh the page and try again.')
        }
        setLoading(false)
        setError(error, customMessage)
      }
    })

    const selectAnswer = (qId: number, aId: number): void => {
      const question = self.questions.find((q) => q.qId === qId)
      const answer = question?.answers.find((a) => a.aId === aId)
      if (question !== undefined && answer !== undefined) {
        question.selectedAnswer = cloneDeep(answer)
      } else {
        throw new Error('Question or Answer not found.')
      }
    }

    const autoAnswerAllQuestions = (): void => {
      self.questions.forEach((question) => {
        const randomAId = Math.floor(
          Math.random() * Math.floor(question.answers.length),
        )
        question.selectedAnswer = cloneDeep(question.answers[randomAId])
      })
    }

    const getFinalAnswers = (): IFinalAnswers => {
      if (!self.allQuestionsAnswered) {
        throw new Error('Can’t find answers for all questions')
      }

      const answersObj: IFinalAnswers = {
        answeredQuestions: self.questions as IAnsweredQuestion[],
        origin: window.location.origin,
        version: self.mScoutVersion,
        userData: self.userData,
      }

      const preppedAnswersObj = prepForApi<IFinalAnswers>(
        answersObj,
        FinalAnswers,
      )

      return preppedAnswersObj
    }

    const submitAnswers = flow(function * () {
      const getSolutions = functions.httpsCallable('getSolutions')

      let answers: IFinalAnswers
      try {
        answers = getFinalAnswers()
      } catch (error) {
        setError(error)
        return
      }

      resetAlert()
      setLoading(true)
      try {
        const response: firebase.functions.HttpsCallableResult =
          yield getSolutions(answers)
        const result: IResult = yield tPromise.decode(Result, response.data)
        setResult(result)
        setLoading(false)
        analytics.logEvent('mscout_result', { result_id: result.resultId })
        const env = getEnv<IRootStoreEnv>(self)
        env.router.push(`/solutions/${result.resultId}/${result.secretId}`)
      } catch (uncastError) {
        const error = uncastError as Error & firebase.functions.HttpsError
        let customMessage = null
        if (error.code === 'invalid-argument') {
          customMessage = 'The data sent to the server was in the wrong format. Your browser may have an older version of the web app cached. Please refresh the page and try again.'
        } else if (error.code === 'internal') {
          customMessage = 'An unexpected error occured. There may be a server issue, or an issue with your internet connection.'
          console.error(error)
          if (error.message !== undefined) {
            customMessage += `\n\nAdditional Error Info: ${error.message}`
          }
        } else if (tPromise.isDecodeError(error)) {
          customMessage = ('The data received from the server was in the wrong format. Your browser may have an older version of the web app cached. Please refresh the page and try again.')
        }
        setLoading(false)
        setError(error, customMessage)
      }
    })

    const completeScreening = (): void => {
      if (self.appState.skipResultEmail) {
        void submitAnswers()
      } else {
        setEmailResultModalOpen(true)
      }
    }

    const setTextInputForUserForAnswer = (qId: number, aId: number, textInputFromUser: string): void => {
      if (self.questions[qId].answers[aId].acceptsTextInput === true) {
        self.questions[qId].answers[aId].textInputFromUser = textInputFromUser
      }
    }

    const setLoading = (loading: boolean): void => {
      self.appState.contentLoading = loading
    }

    const momentarilySetScrolledToName = (name: string): void => {
      self.appState.scrolledToName = name
      window.setTimeout(() => {
        self.appState.scrolledToName = null
      }, 1000)
    }

    const setError = (
      error: unknown | null = null,
      customMessage: string | null = null,
    ): void => {
      let errMessage = customMessage
      if (customMessage === null) {
        errMessage = (error as Error | null)?.message ?? 'Unknown error'
      }

      self.appState.appError = {
        error: error,
        message: errMessage,
      }
      console.error(error)
    }

    const setResult = (result: IResult): void => {
      self.result = cast(result)
      self.displayResult = cast(buildDisplayResult(result))
    }

    const resetCompleteModalDismissed = (): void => {
      self.appState.completeModalDismissed = false
    }

    const dismissCompleteModal = (): void => {
      self.appState.completeModalDismissed = true
      setReadyWhenYouAreAlert()
    }

    const submitContactForm = flow(function * (
      email: string,
      message: string,
    ) {
      const storeContactFormEmail = functions.httpsCallable('storeContactFormEmail')
      const contactFormEmail: IContactFormEmail = {
        from: email,
        body: message,
        relatedResultId: self.result?.resultId,
      }

      try {
        const response: firebase.functions.HttpsCallableResult =
          yield storeContactFormEmail(contactFormEmail)
        if (response.data !== true) {
          throw new Error('Error submitting email form.')
        }
        setContactFormSuccessful(true)
      } catch {
        setContactFormSuccessful(false)
      }
    })

    const submitShareByEmail = flow(function * (
      senderName: string,
      senderEmail: string,
      copyToSender: boolean,
      recipientEmail: string,
      message: string,
    ) {
      const storeShareEmail = functions.httpsCallable('storeShareEmail')

      if (self.result?.resultId === undefined) {
        throw new Error('Missing result ID for sharing email.')
      }

      const shareFormDetails: IShareFormDetails = {
        fromName: senderName,
        from: senderEmail,
        copyToSender,
        to: recipientEmail,
        body: message,
        relatedResultId: self.result.resultId,
      }

      try {
        const response: firebase.functions.HttpsCallableResult =
          yield storeShareEmail(shareFormDetails)
        if (response.data !== true) {
          throw new Error('Error submitting email share form.')
        }
        setEmailShareSuccessful(true)
      } catch {
        setEmailShareSuccessful(false)
      }

      analytics.logEvent('shared_by_email', { result_id: self.result?.resultId })
    })

    const setContactFormSuccessful = (success: boolean | null): void => {
      self.appState.contactFormSuccessful = success
    }

    const setEmailShareSuccessful = (success: boolean | null): void => {
      self.appState.emailShareSuccessful = success
    }

    const setAlert = (
      buttonActionKey: AlertActionKey,
      buttonTitle: string,
      title: string,
      subtitle?: string,
    ): void => {
      self.appState.alert = {
        buttonActionKey,
        buttonTitle,
        subtitle,
        title,
      }
    }

    const setReadyWhenYouAreAlert = (): void => {
      setAlert(
        AlertActionKey.CompleteScreening,
        'Submit',
        'Ready when you are!',
      )
    }

    const resetAlert = (): void => {
      self.appState.alert = null
    }

    return {
      autoAnswerAllQuestions,
      completeScreening,
      dismissCompleteModal,
      markInitComplete,
      momentarilySetScrolledToName,
      resetCompleteModalDismissed,
      revisitResult,
      selectAnswer,
      setAlert,
      setContactFormOpen,
      setContactFormSuccessful,
      setEmailResultModalOpen,
      setEmailShareSuccessful,
      setError,
      setReadyWhenYouAreAlert,
      setSkipResultEmail,
      setTextInputForUserForAnswer,
      setUserData,
      submitAnswers,
      submitContactForm,
      submitShareByEmail,
    }
  })

export default AppStore
