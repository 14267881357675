import React, { Fragment, useState } from 'react'

import {
  IDisplayResultCard,
} from '../src/types'

import {
  Icon,
  Typography,
  Tooltip,
} from 'antd'
import { Collapse } from 'react-collapse'
import { Col, Row } from 'react-flexbox-grid'
import { Element } from 'react-scroll'

import AppDisplayResultTags from './AppDisplayResultTags'
import AppMarkdownWithHtml from './AppMarkdownWithHtml'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const { Title } = Typography

interface IProps {
  index: number
  contents: IDisplayResultCard
}

const AppDisplayResultCard: React.FunctionComponent<IProps> = observer(({
  index,
  contents,
}) => {
  const { app } = useStore()

  const [isCollapsed, setIsCollapsed] = useState(contents.collapsible ?? false)

  // Wrapping elements regardless of if scroll implemented.
  const scrollToName = contents.scrollToName ?? 'noname'
  const highlighted = app.appState.scrolledToName === scrollToName
  const cardClass = 'AppDisplayResultCard' +
    (highlighted ? ' is-highlighted' : '')

  const comments = contents.comments?.map((comment, cIndex) => (
    <Fragment key={`comment${index}-${cIndex}`}>
      <div className="AppDisplayResultCard__section">
        <Icon style={{ paddingRight: '5px' }} type="bulb" />
        <AppMarkdownWithHtml source={comment.title} />
      </div>
      <AppMarkdownWithHtml
        className="AppDisplayResultCard__related-question"
        source={comment.subtitle ?? ''}
      />
    </Fragment>
  ))

  const collapsedClass = isCollapsed ? '' : ' is-open'

  return (
    <Element
      name={scrollToName}
    >
      <Row className={cardClass}>
        <Col xs={12}>
          {
            contents.title !== undefined &&
            <div className="AppDisplayResultCard__header">
              <Title className="AppDisplayResultCard__title" level={4}>
                <div className="AppDisplayResultCard__title-text">
                  {
                    contents.titleIcon !== undefined &&
                    <Icon
                      type={contents.titleIcon}
                      style={{ paddingRight: '10px' }}
                    />
                  }
                  <AppMarkdownWithHtml source={contents.title} />
                </div>
                {
                  contents.tags !== undefined &&
                  contents.tags.length > 0 &&
                  <AppDisplayResultTags
                    keyPrefix={`resultCardTag${index}`}
                    tags={contents.tags}
                  />
                }
              </Title>
              {
                contents.collapsible === true &&
                <Tooltip
                  title={
                    `${isCollapsed ? 'Show' : 'Hide'} Details`
                  }
                >
                  <button
                    className="AppDisplayResultCard__header-btn"
                    onClick={() => { setIsCollapsed(!isCollapsed) }}
                  >
                    <span className="AppDisplayResultCard__header-btn__text">
                      Details
                    </span>
                    <Icon
                      className={
                        `AppDisplayResultCard__header-btn__arrow${collapsedClass}`
                      }
                      type="down"
                    />
                  </button>
                </Tooltip>
              }
            </div>
          }
          <Collapse isOpened={!isCollapsed}>
            {
              contents.paragraph !== undefined &&
              <AppMarkdownWithHtml
                allowParagraphs
                className={
                  'AppDisplayResultCard__section' +
                  (contents.title !== undefined ? ' is-below-title' : '')
                }
                source={contents.paragraph}
              />
            }
            {
              (comments?.length ?? 0) > 0 &&
              <>
                {
                  contents.commentsTitle !== undefined &&
                  <div className="AppDisplayResultCard__section">
                    <strong>{contents.commentsTitle}</strong>
                  </div>
                }
                {comments}
              </>
            }
          </Collapse>
        </Col>
      </Row>
    </Element>
  )
})

export default AppDisplayResultCard
