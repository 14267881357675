import React from 'react'

import { Layout } from 'antd'

import { useStore } from './store'

import { ReactComponent as MavenLogo } from './img/maven.svg'
import { observer } from 'mobx-react-lite'

const { Footer } = Layout

const AppFooter: React.FunctionComponent = observer(() => {
  const { app } = useStore()

  return (
    <Footer className="AppFooter">
      <div className="AppFooter__content">
        <a href="https://www.mavenwe.com/">
          <MavenLogo
            className="AppFooter__MavenLogo"
            title="Maven Water &amp; Environment"
          />
        </a>
      </div>
      <div className="AppFooter__content">
        <div><sub className="m">m</sub>Scout version {app.mScoutVersion}</div>
        <div>© {new Date().getFullYear()} Maven Water &amp; Environment</div>
        <div>
          <button
            className="AppFooter__button"
            onClick={() => app.setContactFormOpen(true)}
          >
            Contact Us
          </button>
        </div>
      </div>
    </Footer>
  )
})

export default AppFooter
