import React, { useEffect, useState } from 'react'
import { Button, Typography, Checkbox } from 'antd'
import { Col, Row } from 'react-flexbox-grid'

import AppAnswers from './AppAnswers'
import AppPrevNext from './AppPrevNext'
import { ElementTransition, FromBottomTransition, ToLeftTransition } from './transitions'
import config, { Stage } from './config'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const { Title } = Typography

const AppQuestionContent: React.FunctionComponent = observer(() => {
  const { app, router } = useStore()

  const question = app.questions[router.currentQId]

  const [transitionedIn, setTransitionedIn] = useState(false)

  useEffect(() => {
    setTransitionedIn(true)
  }, [])

  const transitionKeyNum = transitionedIn ? question.qId : 999

  const handleClickAutoAnswer = (): void => {
    app.autoAnswerAllQuestions()
    app.completeScreening()
  }

  const handleToggleResultEmail = (event: CheckboxChangeEvent): void => {
    app.setSkipResultEmail(event.target.checked)
  }

  // Hot tip: In order for the transitions between questions to be beautiful,
  // the transitioning away component cannot be associated with the selected
  // question in the app state, as that will update at the start of the
  // transition. That is why there is a qId assigned to the answers component
  // below, rather than the component internally reflecting the currently
  // selected question.

  const preamble = (
    <Row className="AppQuestionContent__preamble">
      <Col xs={12}>
        <Title className="AppQuestionContent__preamble-title" level={1}>
          Welcome to <sub className="m">m</sub>Scout!
        </Title>
      </Col>
      <Col xs={12}>
        <Title className="AppQuestionContent__preamble-subtitle" level={2}>
          <sub className="m">m</sub>Scout uses your specifications to quickly
          and easily sort through mine water treatment technologies and give you
          customized recommendations with site- and contaminant-specific
          considerations. Answer 9 simple multiple choice questions and
          we will email you your summary of potential mine water treatment
          technologies.
        </Title>
      </Col>
    </Row>
  )

  const answers = (
    <AppAnswers qId={question.qId} />
  )

  return (
    <>
      <ElementTransition
        queueOrderForCSS={1}
        transComponent={ToLeftTransition}
        transitionedIn={transitionedIn}
        transKey={`qTransTitle${transitionKeyNum}`}
      >
        <div>
          { question.qId === 0 && preamble }
          <Row className="AppQuestionContent__titles">
            <Col xs={12}>
              <Title className="AppQuestionContent__title" level={3}>
                {question.title}
              </Title>
            </Col>
            {
              question.subtitle !== null &&
              <Col xs={12}>
                <Title className="AppQuestionContent__subtitle" level={4}>
                  {question.subtitle}
                </Title>
              </Col>
            }
          </Row>
        </div>
      </ElementTransition>
      <ElementTransition
        queueOrderForCSS={2}
        transComponent={ToLeftTransition}
        transitionedIn={transitionedIn}
        transKey={`qTransAnswers${transitionKeyNum}`}
      >
        {answers}
      </ElementTransition>
      <ElementTransition
        transComponent={FromBottomTransition}
        transitionedIn={transitionedIn}
        transKey={`qTransPrevNext${transitionKeyNum}`}
      >
        <Row className="AppQuestion__prevnext">
          <AppPrevNext />
        </Row>
      </ElementTransition>
      {
        config.stage !== Stage.Prod &&
        <ElementTransition
          transComponent={FromBottomTransition}
          transitionedIn={transitionedIn}
          transKey={`qTransAutoAnswer${transitionKeyNum}`}
        >
          <div className="AppQuestionContent__admin">
            <Button
              className="AppQuestionContent__admin-row"
              onClick={() => handleClickAutoAnswer()}
            >
              Auto-Answer and Submit
            </Button>
            <Checkbox
              className="AppQuestionContent__admin-row"
              defaultChecked={app.appState.skipResultEmail}
              onChange={handleToggleResultEmail}
            >
              Skip Result Email
            </Checkbox>
          </div>
        </ElementTransition>
      }
    </>
  )
})

export default AppQuestionContent
