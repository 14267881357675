import React, { useState } from 'react'

import { Button, Icon } from 'antd'
import AppCopyInput from './AppCopyInput'
import AppEmailShareModal from './AppEmailShareModal'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const AppResultDetails: React.FunctionComponent = observer(() => {
  const { app, router } = useStore()
  if (app.result === null) {
    throw new Error('Missing result data for AppResultDetails.')
  }

  const [shareModalVisible, setShareModalVisible] = useState(false)

  const resultId = app.result.resultId
  const secretId = app.result.secretId

  return (
    <div className="AppResultDetails">
      <div className="AppResultDetails__result-content">
        <div className="AppResultDetails__title">
          {
            router.isShared ? 'We’ve saved this result.'
              : 'We’ve saved your result for you.'
          }
        </div>
        <div className="AppResultDetails__result-id-text">
          {router.isShared ? 'This Result’s ' : 'Your Result '}
          ID is {resultId}.<br />
          Revisit or share {router.isShared ? 'this' : 'your'} result with this link.
        </div>
        <AppCopyInput
          value={`${window.location.origin}/solutions/${resultId}/${secretId}`}
        />
      </div>
      <div className="AppResultDetails__share-btn">
        <Button
          className="AppResultDetails__Button"
          onClick={() => setShareModalVisible(true)}
          type="primary"
        >
          <Icon type="user" />
          Share result by email
        </Button>
      </div>
      <div className="AppResultDetails__contact-content">
        <div className="AppResultDetails__title">
          We’d love to hear from you!
        </div>
        <div>
          Click the button below to send us a message, and we will automatically
          receive a copy of your result. You can also email us directly at{' '}
          <a href="mailto:mscoutweb@mavenwe.com">mscoutweb@mavenwe.com</a>.
        </div>
      </div>
      <div className="AppResultDetails__contact-btn">
        <Button
          className="AppResultDetails__Button"
          onClick={() => app.setContactFormOpen(true)}
          type="primary"
        >
          <Icon type="mail" />
          Get in touch
        </Button>
      </div>
      <AppEmailShareModal
        closeModal={() => setShareModalVisible(false)}
        visible={shareModalVisible}
      />
    </div>
  )
})

export default AppResultDetails
