import React from 'react'

import { IDisplayResultHeading } from '../src/types'

import {
  Icon,
  Typography,
} from 'antd'
import { Col, Row } from 'react-flexbox-grid'
import ReactMarkdown from 'react-markdown'

import {
  ElementTransition,
  ToLeftTransition,
} from './transitions'

const { Title } = Typography

interface IProps {
  index: number
  queueOrder: number
  transitionedIn: boolean
  transKey: string
  heading: IDisplayResultHeading
}

const AppDisplayResultHeading: React.FunctionComponent<IProps> = ({
  index,
  queueOrder,
  transitionedIn,
  transKey,
  heading,
}) => {
  return (
    <>
      <ElementTransition
        queueOrderForCSS={queueOrder}
        transComponent={ToLeftTransition}
        transitionedIn={transitionedIn}
        transKey={transKey}
      >
        <Row className="AppDisplayResultHeading">
          <Col xs={12} className="AppDisplayResultHeading__split-container">
            <Title className="AppDisplayResultHeading__title" level={3}>
              {heading.title}
            </Title>
          </Col>
          {
            heading.comments !== undefined &&
            heading.comments.length > 0 &&
            heading.comments.map(
              (comment, cIndex) => (
                <Col key={`copcComment-${index}-${cIndex}`} xs={12}>
                  <Title className="AppDisplayResultHeading__subtitle" level={4}>
                    <Icon style={{ paddingRight: '10px' }} type="bulb" />
                    <ReactMarkdown
                      renderers={{
                        paragraph: props => <span {...props} />,
                      }}
                      source={comment.title}
                    />
                  </Title>
                </Col>
              ),
            )
          }
        </Row>
      </ElementTransition>
    </>
  )
}

export default AppDisplayResultHeading
