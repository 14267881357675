import React from 'react'

import {
  TagType,
  IDisplayResultTag,
} from '../src/types'

import {
  Tag,
} from 'antd'

interface IProps {
  keyPrefix: string
  tags: IDisplayResultTag[]
}

const AppDisplayResultTags: React.FunctionComponent<IProps> = ({
  keyPrefix,
  tags,
}) => {
  const displayTags: JSX.Element[] = []
  tags.forEach((tag, index) => {
    const tagColors = {
      [TagType.TreatmentType]: 'rgba(254,254,254,0.4)',
      [TagType.TreatmentClass]: 'rgba(254,254,254,0.3)',
      [TagType.TransferOrTransformation]: 'rgba(254,254,254,0.2)',
    }
    displayTags.push(<Tag
      className="AppDisplayResultTags__Tag"
      color={tagColors[tag.type]}
      key={`${keyPrefix}-${index}`}
    >
      {tag.title}
    </Tag>)
  })
  return (
    <div className="AppDisplayResultTags">
      {displayTags}
    </div>
  )
}

export default AppDisplayResultTags
