import React from 'react'

import {
  DisplayResultBodyElement,
  DisplayResultBodyElementType,
} from '../src/types'
import {
  ElementTransition,
  ToLeftTransition,
} from './transitions'
import AppDisplayResultCard from './AppDisplayResultCard'
import AppDisplayResultAnswerRecap from './AppDisplayResultAnswerRecap'
import AppDisplayResultCallToAction from './AppDisplayResultCallToAction'

interface IProps {
  index: number
  queueOrder: number
  transitionedIn: boolean
  transKey: string
  body: DisplayResultBodyElement[]
}

const AppDisplayResultBody: React.FunctionComponent<IProps> = ({
  index,
  queueOrder,
  transitionedIn,
  transKey,
  body,
}) => {
  const bodyElementContents: JSX.Element[] = []
  body.forEach((element, bIndex) => {
    switch (element.type) {
      case DisplayResultBodyElementType.Card:
        bodyElementContents.push(<AppDisplayResultCard
          index={bIndex}
          contents={element.contents}
          key={`appDispCard-${index}-${bIndex}`}
        />)
        break
      case DisplayResultBodyElementType.AnswerRecap:
        bodyElementContents.push(<AppDisplayResultAnswerRecap
          contents={element.contents}
          key={`appDispAR-${index}-${bIndex}`}
        />)
        break
      case DisplayResultBodyElementType.CallToAction:
        bodyElementContents.push(<AppDisplayResultCallToAction
          contents={element.contents}
          key={`appDispCTA-${index}-${bIndex}`}
        />)
    }
  })

  return (
    <ElementTransition
      queueOrderForCSS={queueOrder}
      transComponent={ToLeftTransition}
      transitionedIn={transitionedIn}
      transKey={transKey}
    >
      <div>
        {bodyElementContents}
      </div>
    </ElementTransition>
  )
}

export default AppDisplayResultBody
