import React from 'react'
import { Steps } from 'antd'

import { idToNum } from '../shared/utils'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const { Step } = Steps

const AppSteps: React.FunctionComponent = observer(() => {
  const { app, router } = useStore()

  const { push } = router

  const steps = app.questions.map((question, index, questions) => {
    const alignTooltip = index < 0.5 * questions.length ? 'left' : 'right'
    const isCurrent = index === router.currentQId
    let status: 'wait' | 'process' | 'finish' | 'error' | undefined = 'wait'
    if (isCurrent) {
      status = 'process'
    } else if (question.selectedAnswer !== null) {
      status = 'finish'
    }
    return (
      <Step
        className={`AppSteps__Step AppSteps__Step--tooltip-${alignTooltip}`}
        key={`Step${index}`}
        status={status}
        title={question.title}
      />
    )
  })

  return (
    <Steps
      className="AppSteps"
      type="navigation"
      size="small"
      current={router.currentQId}
      onChange={(newQId) => push(`/q/${idToNum(newQId)}`)}
    >
      {steps}
    </Steps>
  )
})

export default AppSteps
