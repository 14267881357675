import React from 'react'
import { hot } from 'react-hot-loader/root'
import { observer } from 'mobx-react-lite'

import 'react-toastify/dist/ReactToastify.css'
import './App.scss'

import 'focus-visible'

import { Button, Layout, Modal, Tooltip } from 'antd'
import { ToastContainer } from 'react-toastify'

import AppContent from './AppContent'
import AppFooter from './AppFooter'

import { useStore } from './store'

import logo1x from './img/mScout-250-1x.png'
import logo2x from './img/mScout-250-2x.png'
import logo3x from './img/mScout-250-3x.png'
import AppTopBanner from './AppTopBanner'
import AppContactModal from './AppContactModal'
import { ModalFuncProps } from 'antd/lib/modal'
import AppEmailResultModal from './AppEmailResultModal'
import config, { Stage } from './config'

const { Header, Content } = Layout
const { confirm } = Modal

const App: React.FunctionComponent = observer(() => {
  const { app } = useStore()

  const handleLogoClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    if (app.questionsAnswered !== 0) {
      const modalProperties: ModalFuncProps = {
        cancelText: 'Cancel',
        okText: 'Start Over',
        title: 'Are you sure you want to start over?',
        onOk () {
          window.location.href = '/'
        },
      }
      if (app.result === null) {
        modalProperties.content = 'Any progress will be lost.'
      }
      confirm(modalProperties)
    } else {
      window.location.href = '/'
    }
  }

  return (
    <Layout className="App">
      <ToastContainer className="App__ToastContainer" />
      <Header className="App__Header">
        <Tooltip title="Start Over" overlayClassName="App__Header__Tooltip">
          <a href="/" onClick={(e) => handleLogoClick(e)}>
            <img
              alt="mScout"
              className="App__Header__img"
              srcSet={`${logo1x},
              ${logo2x} 2x,
              ${logo3x} 3x`}
              src={logo1x}
            />
          </a>
        </Tooltip>
        <Button
          className="App__Header__Button"
          href="https://www.mavenwe.com"
          size="small"
        >
          Maven Website
        </Button>
      </Header>
      <AppTopBanner />
      <Content className="App__Content">
        <AppContent appError={app.appState.appError} />
      </Content>
      <AppFooter />
      <AppContactModal />
      <AppEmailResultModal />
    </Layout>
  )
})

export default config.stage === Stage.DevEmulated || config.stage === Stage.Dev ? hot(App) : App
