import React from 'react'

import {
  IDisplayResultCallToAction, DisplayResultCallToActionKey,
} from '../src/types'

import {
  Icon,
  Button,
} from 'antd'
import { Col, Row } from 'react-flexbox-grid'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

interface IProps {
  contents: IDisplayResultCallToAction
}

const AppDisplayResultCallToAction: React.FunctionComponent<IProps> = observer(({
  contents,
}) => {
  const { app } = useStore()

  const buttonActions = {
    [DisplayResultCallToActionKey.OpenContactForm] () {
      app.setContactFormOpen(true)
    },
  }

  const handleButtonClick = (): void => {
    if (contents.buttonActionKey !== undefined) {
      buttonActions[contents.buttonActionKey]()
    }
  }

  return (
    <Row className="AppDisplayResultCallToAction">
      <Col xs={12}>
        <div className="AppDisplayResultCallToAction__content">
          <div className="AppDisplayResultCallToAction__title">
            {contents.title}
          </div>
          <div className="AppDisplayResultCallToAction__btn-container">
            <Button
              className="AppDisplayResultCallToAction__Button"
              onClick={handleButtonClick}
              type="primary"
            >
              <Icon type={contents.buttonIcon} />
              {contents.buttonTitle}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  )
})

export default AppDisplayResultCallToAction
