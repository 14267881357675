import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  Icon,
  Spin,
} from 'antd'
import { Slide, toast } from 'react-toastify'
import { format } from 'date-fns'

import config, { Stage } from './config'

// import AppPDF from "./AppPDF";
// import {
//   ElementTransition,
//   FromBottomTransition
// } from "./transitions";

import AppDisplayResultHeading from './AppDisplayResultHeading'
import AppDisplayResultBody from './AppDisplayResultBody'
import { ElementTransition, FromBottomTransition } from './transitions'
import { useStore } from './store'
import { AlertActionKey } from './types'
import { observer } from 'mobx-react-lite'

interface IParams {
  resultId?: string
  secretId?: string
}

const AppResult: React.FunctionComponent = observer(() => {
  const { app, router } = useStore()

  const { resultId, secretId } = useParams<IParams>()

  const displayResult = app.displayResult

  const [transitionedIn, setTransitionedIn] = useState(false)

  useEffect(() => {
    if (displayResult !== null) {
      if (!transitionedIn) {
        const ScrollDownMsg: React.FunctionComponent = () => (
          <div>
            <Icon
              className="ScrollDownMsg__Icon"
              type="down"
            />
            Scroll down to view the result.
          </div>
        )

        toast(<ScrollDownMsg />, {
          delay: 500,
          position: 'top-center',
          transition: Slide,
        })
        setTransitionedIn(true)
      }
      app.resetCompleteModalDismissed()

      if (app.appState.revisitResultMode) {
        let calcDate = 'previously'
        let mscoutVMessage = ''
        if (displayResult !== null) {
          calcDate = format(
            new Date(displayResult.date),
            "iii, LLL d, y 'at' p",
          )
          mscoutVMessage = ` with <sub class="m">m</sub>Scout ${displayResult.version}`
        }
        const welcome = router.isShared ? 'Welcome to mScout!' : 'Welcome Back!'
        app.setAlert(
          AlertActionKey.ReloadSite,
          'Start Over',
          welcome,
          `This result was calculated ${calcDate}${mscoutVMessage}.`,
        )
      }
    }
  }, [
    app,
    router.isShared,
    transitionedIn,
    displayResult,
  ])

  if (displayResult === null) {
    if (app.appState.init) { throw new Error('Failed to load solutions.') }
    if (app.appState.contentLoading) {
      return <Spin style={{ width: '100%' }} />
    } else {
      void app.revisitResult(resultId, secretId)
      return <Spin style={{ width: '100%' }} />
    }
  }

  if (resultId === undefined || secretId === undefined) {
    throw new Error('Incorrect Solutions URL.')
  }

  const displayResultSections: JSX.Element[] = []
  const transitionKeyNum = transitionedIn ? 0 : 1
  let queueOrder = 0

  displayResult.sections.forEach((section, index) => {
    if (section.heading !== undefined) {
      displayResultSections.push(<AppDisplayResultHeading
        index={index}
        queueOrder={++queueOrder}
        transitionedIn={transitionedIn}
        transKey={`resultTransition${queueOrder}-${transitionKeyNum}`}
        heading={section.heading}
        key={`dispResHead${index}`}
      />)
    }
    displayResultSections.push(<AppDisplayResultBody
      index={index}
      queueOrder={++queueOrder}
      transitionedIn={transitionedIn}
      transKey={`resultTransition${queueOrder}-${transitionKeyNum}`}
      body={section.body}
      key={`dispResBody${index}`}
    />)
  })

  return (
    <Spin spinning={app.appState.contentLoading}>
      {displayResultSections}
      {/* <ElementTransition
        transComponent={FromBottomTransition}
        transitionedIn={transitionedIn}
        transKey={`rTransPDF${transitionKeyNum}`}
      >
        <div className="AppResult__pdf">
          <AppPDF />
        </div>
      </ElementTransition> */}
      {
        config.stage !== Stage.Prod &&
        <ElementTransition
          transComponent={FromBottomTransition}
          transitionedIn={transitionedIn}
          transKey={`rTransPDF${transitionKeyNum}`}
        >
          <div className="AppResult__admin">
            <Button
              className="AppResult__admin-row"
              onClick={() => { void app.submitAnswers() }}
            >
              Re-submit Same Answers
            </Button>
          </div>
        </ElementTransition>
      }
    </Spin>
  )
})

export default AppResult
