import { SnapshotIn, types } from 'mobx-state-tree'

// Types needed for the app only (not API)

// Type naming conventions:
// const MAnswer - mst runtime type
// interface IAnswer - typescript type
// const MIAppState - mst runtime type including default values for intialization

/* #region  App State Types */

// Derived from built-in javascript Error type in lib.es5.d.ts
// const MError = types.model({
//   name: types.maybe(types.string),
//   message: types.maybe(types.string),
//   stack: types.maybe(types.string),
// })

// export interface IAppError {
//   error: Error | null
//   message: string | null
// }

export const MAppError = types.model('AppError', {
  error: types.frozen(),
  message: types.maybeNull(types.string),
})

export interface IAppError extends SnapshotIn<typeof MAppError> { }

export enum AlertActionKey {
  CompleteScreening = 'CompleteScreening',
  ReloadSite = 'ReloadSite',
}

const MAlertActionKey = types.enumeration<AlertActionKey>(
  'AlertActionKey',
  Object.values(AlertActionKey),
)

export const MAlert = types.model('Alert', {
  buttonActionKey: MAlertActionKey,
  buttonTitle: types.string,
  subtitle: types.maybe(types.string),
  title: types.string,
})

export interface IAlert extends SnapshotIn<typeof MAlert> { }

// export interface IAlert {
//   buttonAction: () => void
//   buttonTitle: string
//   subtitle?: string
//   title: string
// }

export const MIAppState = types.model('AppState', {
  alert: types.optional(types.maybeNull(MAlert), null),
  appError: types.optional(types.maybeNull(MAppError), null),
  completeModalDismissed: false,
  contactFormOpen: false,
  contactFormSuccessful: types.optional(types.maybeNull(types.boolean), null),
  contentLoading: false,
  emailShareSuccessful: types.optional(types.maybeNull(types.boolean), null),
  emailResultModalOpen: false,
  init: false,
  revisitResultMode: false,
  scrolledToName: types.optional(types.maybeNull(types.string), null),
  skipResultEmail: false,
})

/* #endregion */

/* #region  For Displaying Results */

export interface IDisplayResultComment {
  title: string
  subtitle?: string
}

const MDisplayResultComment = types.model({
  title: types.string,
  subtitle: types.maybe(types.string),
})

export interface IDisplayResultHeading {
  title: string
  isIntro?: boolean
  resultId?: string
  secretId?: string
  comments?: IDisplayResultComment[]
}

const MDisplayResultHeading = types.model({
  title: types.string,
  isIntro: types.maybe(types.boolean),
  resultId: types.maybe(types.string),
  secretId: types.maybe(types.string),
  comments: types.maybe(types.array(MDisplayResultComment)),
})

export enum TitleIcon {
  Info = 'info-circle',
  Solution = 'check-circle',
}

const MTitleIcon = types.enumeration<TitleIcon>(
  'TitleIcon',
  Object.values(TitleIcon),
)

export enum TagType {
  TreatmentType = 'treatment-type',
  TreatmentClass = 'treatment-class',
  TransferOrTransformation = 'transfer-or-transformation',
}

const MTagType = types.enumeration<TagType>(
  'TagType',
  Object.values(TagType),
)

export interface IDisplayResultTag {
  title: string
  type: TagType
}

const MDisplayResultTag = types.model('DisplayResultTag', {
  title: types.string,
  type: MTagType,
})

export interface IDisplayResultAnswerRecapForQuestion {
  qId: number
  qNum: number
  qTitle: string
  aTitle: string
}

const MDisplayResultAnswerRecapForQuestion = types.model(
  'DisplayResultAnswerRecapForQuestion',
  {
    qId: types.number,
    qNum: types.number,
    qTitle: types.string,
    aTitle: types.string,
  },
)

export enum DisplayResultBodyElementType {
  CallToAction = 'callToAction',
  Card = 'card',
  AnswerRecap = 'answers',
}

// export interface IDisplayResultCard {
//   collapsible?: boolean
//   comments?: IDisplayResultComment[]
//   commentsTitle?: string
//   paragraph?: string
//   scrollToName?: string
//   tags?: IDisplayResultTag[]
//   title?: string
//   titleIcon?: TitleIcon
// }

const MDisplayResultCard = types.model('DisplayResultCard', {
  collapsible: types.maybe(types.boolean),
  comments: types.maybe(types.array(MDisplayResultComment)),
  commentsTitle: types.maybe(types.string),
  paragraph: types.maybe(types.string),
  scrollToName: types.maybe(types.string),
  tags: types.maybe(types.array(MDisplayResultTag)),
  title: types.maybe(types.string),
  titleIcon: types.maybe(MTitleIcon),
})

export interface IDisplayResultCard extends SnapshotIn<
  typeof MDisplayResultCard
> { }

// export interface IDisplayResultAnswerRecap {
//   answerRecap: IDisplayResultAnswerRecapForQuestion[]
// }

const MDisplayResultAnswerRecap = types.model('DisplayResultAnswerRecap', {
  answerRecap: types.array(MDisplayResultAnswerRecapForQuestion),
})

export interface IDisplayResultAnswerRecap extends SnapshotIn<
  typeof MDisplayResultAnswerRecap
> { }

export enum DisplayResultCallToActionKey {
  OpenContactForm = 'OpenContactForm'
}

const MDisplayResultCallToActionKey = types.enumeration<
DisplayResultCallToActionKey
>(
  'DisplayResultCallToActionKey',
  Object.values(DisplayResultCallToActionKey),
)

// export interface IDisplayResultCallToAction {
//   buttonActionKey: DisplayResultCallToActionKey
//   buttonIcon?: string
//   buttonTitle: string
//   title: string
// }

const MDisplayResultCallToAction = types.model('DisplayResultCallToAction', {
  buttonActionKey: MDisplayResultCallToActionKey,
  buttonIcon: types.maybe(types.string),
  buttonTitle: types.string,
  title: types.string,
})

export interface IDisplayResultCallToAction extends SnapshotIn<
  typeof MDisplayResultCallToAction
> { }

// export type DisplayResultBodyElement = {
//   type: DisplayResultBodyElementType.Card
//   contents: IDisplayResultCard
// } | {
//   type: DisplayResultBodyElementType.AnswerRecap
//   contents: IDisplayResultAnswerRecap
// } | {
//   type: DisplayResultBodyElementType.CallToAction
//   contents: IDisplayResultCallToAction
// }

// const MDisplayResultBodyElement = types.union(
//   types.model({
//     type: 'card',
//     contents: MDisplayResultCard,
//   }),
//   types.model({
//     type: DisplayResultBodyElementType.AnswerRecap,
//     contents: MDisplayResultAnswerRecap,
//   }),
//   types.model({
//     type: DisplayResultBodyElementType.CallToAction,
//     contents: types.model({
//       buttonActionKey: types.string,
//     }),
//   }),
// )

const MDisplayResultBodyElement = types.union(
  types.model({
    type: types.literal(DisplayResultBodyElementType.Card),
    contents: MDisplayResultCard,
  }),
  types.model({
    type: types.literal(DisplayResultBodyElementType.AnswerRecap),
    contents: MDisplayResultAnswerRecap,
  }),
  types.model({
    type: types.literal(DisplayResultBodyElementType.CallToAction),
    contents: MDisplayResultCallToAction,
  }),
)

export type DisplayResultBodyElement = SnapshotIn<
  typeof MDisplayResultBodyElement
>

// export interface IDisplayResultSection {
//   heading?: IDisplayResultHeading
//   body: DisplayResultBodyElement[]
// }

const MDisplayResultSection = types.model('DisplayResultSection', {
  heading: types.maybe(MDisplayResultHeading),
  body: types.array(MDisplayResultBodyElement),
})

export interface IDisplayResultSection extends SnapshotIn<
  typeof MDisplayResultSection
> { }

// export interface IDisplayResult {
//   date: number
//   version: string
//   sections: IDisplayResultSection[]
// };

export const MDisplayResult = types.model('DisplayResult', {
  date: types.number,
  version: types.string,
  sections: types.array(MDisplayResultSection),
})

export interface IDisplayResult extends SnapshotIn<
  typeof MDisplayResult
> { }

/* #endregion */
