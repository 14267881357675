import React, { ReactChild } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

interface IToLeftTransitionProps {
  children: ReactChild
  queueOrderForCSS?: number
}

interface IFromBottomTransitionProps {
  children: ReactChild
}

interface IFromTopTransitionProps {
  children: ReactChild
}

interface IElementTransitionProps {
  children: ReactChild
  queueOrderForCSS?: number
  transComponent: React.ElementType<IToLeftTransitionProps>
  transitionedIn: boolean
  transKey: string
}

export const ElementTransition: React.FunctionComponent<
IElementTransitionProps
> = (props) => {
  const {
    children,
    transKey,
    transitionedIn,
    transComponent,
    ...otherProps
  } = props
  const TransitionElement = transComponent
  return (
    <SwitchTransition mode="out-in">
      <TransitionElement
        {...otherProps}
        key={transKey}
      >
        {transitionedIn ? children : <div></div>}
      </TransitionElement>
    </SwitchTransition>
  )
}

export const ToLeftTransition: React.FunctionComponent<
IToLeftTransitionProps
> = (props) => {
  const { queueOrderForCSS, ...otherProps } = props
  if (queueOrderForCSS === undefined) {
    throw new Error('Order must be specified for ToLeftTransition.')
  }
  return (
    <CSSTransition
      {...otherProps}
      timeout={275}
      classNames={{
        enter: `toLeft${queueOrderForCSS}-enter`,
        enterActive: `toLeft${queueOrderForCSS}-enter-active`,
        exit: `toLeft${queueOrderForCSS}-exit`,
      }}
    />
  )
}

export const FromBottomTransition: React.FunctionComponent<
IFromBottomTransitionProps
> = (props) => {
  return (
    <CSSTransition
      {...props}
      timeout={300}
      classNames={{
        enter: 'fromBottom-enter',
        enterActive: 'fromBottom-enter-active',
        exit: 'fromBottom-exit',
      }}
    />
  )
}

export const FromTopTransition: React.FunctionComponent<
IFromTopTransitionProps
> = (props) => {
  return (
    <CSSTransition
      {...props}
      timeout={300}
      classNames={{
        enter: 'fromTop-enter',
        enterActive: 'fromTop-enter-active',
        exit: 'fromTop-exit',
      }}
    />
  )
}
