import React, { Component } from 'react'

import { Button, Typography } from 'antd'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router'

import store from './store'
import AppQuestion from './AppQuestion'
import AppResult from './AppResult'
import config, { Stage } from './config'
import AppTest from './AppTest'
import { IAppError } from './types'

const { Title } = Typography

interface IProps extends RouteComponentProps {
  appError: IAppError | null
}

interface IState {
  useBetaAnyway: boolean
}

const allowedInRouteForFirstLoad = [
  '/solutions/',
  '/test',
]

// Usage of Observer with mobx-react-lite and class components is unclear.
// It may be possible to observe items wrapped in <Observe> in templates.
// Initial store reads and calling actions seems to work. Watching for changes
// after that point does not. As such observing for errors has been moved to
// the parent of this component.

// The reason for leaving this component as a class is Error Boundaries
// are not available in functional components.

export class AppContent extends Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      useBetaAnyway: false, // TODO: robots.txt for staging
    }
  }

  public componentDidMount (): void {
    // Handle initial page load routing
    if (!store.app.appState.init) {
      const { history } = this.props
      if (history.location.pathname !== '/') {
        // Redirect home unless user is revisiting a result.
        let redirectHome = true
        for (const part of allowedInRouteForFirstLoad) {
          if (history.location.pathname.includes(part)) {
            redirectHome = false
            break
          }
        }
        if (redirectHome) {
          history.push('/')
        }
      } else {
        store.app.markInitComplete()
      }
    }
  }

  public componentDidCatch (error: Error | null): void {
    const err = error ?? new Error('Error was swallowed during propagation.')
    const errMessage = err.message ?? 'Unknown error'
    store.app.setError(err, errMessage)
  }

  public render (): React.ReactNode {
    if (this.props.appError !== null) {
      return (
        <div className="AppContent--error">
          <Title level={3}>We’re sorry, there was an unexpected error.</Title>
          <p>Please contact us at{' '}
            <a href="mailto:mscout@mavenwe.com">mscout@mavenwe.com</a>{' '}
            for assistance, or to share information on what happened
            in order to help us solve the problem.
          </p>
          <p></p>
          <Title level={4}>Error Information:</Title>
          <p>{this.props.appError.message}</p>
          <Button
            onClick={() => { window.location.href = '/' }}
          >
            Reload mScout
          </Button>
        </div>
      )
    }

    if (config.stage === Stage.Staging && !this.state.useBetaAnyway) {
      return (
        <div className="AppContent--beta">
          <Title level={3}>
            <p>Welcome to the beta version of mScout.</p>
            <p>Continue to the main mScout website here:</p>
          </Title>
          <p>
            <Button type="primary" href="https://mscout.mavenwe.com">mScout</Button>
          </p>
          <p>
            <Button onClick={() => this.setState({ useBetaAnyway: true })}>
              Use Beta
            </Button>
          </p>
        </div>
      )
    }

    return (
      <Switch>
        <Route path="/solutions/:resultId/:secretId" component={AppResult} />
        <Route path="/test" component={AppTest} />
        <Route path="/" component={AppQuestion} />
      </Switch>
    )
  }
}

export default withRouter(AppContent)
