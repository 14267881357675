import { types, Instance } from 'mobx-state-tree'
import { syncHistoryWithStore } from 'mst-react-router'
import { createBrowserHistory } from 'history'
import makeInspectable from 'mobx-devtools-mst'

import AppStore from './app.store'
import RouterStore from './router.store'
import { createContext, useContext } from 'react'
import { reaction } from 'mobx'

export const RootStore = types
  .model('RootStore', {
    app: AppStore,
    router: RouterStore,
  })
  .views(self => ({
    get currentQId () {
      return self.router.currentQId
    },
  }))

export interface IRootStoreEnv {
  router: Instance<typeof RouterStore>
}

const app = AppStore.create()
const router = RouterStore.create()
const env: IRootStoreEnv = { router }

export const syncedHistory = syncHistoryWithStore(
  createBrowserHistory(),
  router,
)

const store = RootStore.create({
  app,
  router,
}, env)

export const StoreContext = createContext(store)
export const useStore = (): typeof store => useContext(StoreContext)
export const StoreProvider = StoreContext.Provider

// Router Reactions

reaction(
  () => router.location,
  () => {
    if (!router.initialPathNameObserved) {
      // Skip re-tracking initial page load event via HubSpot
      router.markInitialPathNameObserved()
    } else {
      // Note: On some page loads, the user will be redirected to '/', which
      // will trigger a tracking event. For example, loading the route '/q/2'
      const _hsq = window._hsq = window._hsq ?? []
      _hsq.push(['setPath', router.location.pathname])
      _hsq.push(['trackPageView'])
    }
  },
)

makeInspectable(store)

export default store
