import React, { useEffect, useState } from 'react'

import { Spin } from 'antd'
import { Grid, Row } from 'react-flexbox-grid'

import AppQuestionContent from './AppQuestionContent'
import AppSteps from './AppSteps'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const AppQuestion: React.FunctionComponent = observer(() => {
  const { app } = useStore()

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (!mounted && app.result !== null) {
      // User is revisiting the questions after receiving a result.
      setTimeout(() => {
        app.setReadyWhenYouAreAlert()
      }, 100)
    }
    setMounted(true)
  }, [app, mounted])

  return (
    <Spin spinning={app.appState.contentLoading}>
      <Grid fluid className="AppQuestion">
        <Row className="AppQuestion__steps">
          <AppSteps />
        </Row>
        <AppQuestionContent />
      </Grid>
    </Spin>
  )
})

export default AppQuestion
