import React from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Icon,
  List,
  Tooltip,
} from 'antd'
import { Col, Row } from 'react-flexbox-grid'

import { useStore } from './store'
import {
  IDisplayResultAnswerRecap,
  IDisplayResultAnswerRecapForQuestion,
} from '../src/types'
import { observer } from 'mobx-react-lite'

interface IProps {
  contents: IDisplayResultAnswerRecap
}

const AppDisplayResultAnswerRecap: React.FunctionComponent<IProps> = observer(({
  contents,
}) => {
  const { app } = useStore()

  return (
    <Row className="AppDisplayResultAnswerRecap">
      <Col xs={12}>
        <List
          dataSource={contents.answerRecap}
          renderItem={(answerRecapForQ: IDisplayResultAnswerRecapForQuestion) => {
            return (
              <List.Item key={`aRecapForQ${answerRecapForQ.qId}`}>
                <List.Item.Meta
                  avatar={
                    <Avatar className="AppDisplayResultAnswerRecap__Avatar">
                      {answerRecapForQ.qNum}
                    </Avatar>
                  }
                  title={
                    <>
                      {answerRecapForQ.qTitle}&nbsp;&nbsp;
                      {
                        !app.appState.revisitResultMode &&
                        <Link
                          className="AppDisplayResultAnswerRecap__Link"
                          to={`/q/${answerRecapForQ.qNum}`}
                        >
                          <Tooltip
                            arrowPointAtCenter
                            overlayClassName="AppDisplayResultAnswerRecap__Tooltip"
                            title="Change Answer"
                          >
                            <Icon
                              className="AppDisplayResultAnswerRecap__Link__Icon"
                              type="edit"
                            />
                          </Tooltip>
                        </Link>
                      }
                    </>
                  }
                  description={answerRecapForQ.aTitle}
                />
              </List.Item>
            )
          }}
        />
      </Col>
    </Row>
  )
})

export default AppDisplayResultAnswerRecap
