import React, { useState, useEffect } from 'react'

import { FormComponentProps } from '../node_modules/antd/lib/form/Form'
import { Alert, Button, Form, Input, Modal } from 'antd'
import { sanitize } from '../shared/utils'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const { TextArea } = Input

interface IProps extends FormComponentProps { }

const AppContactModal: React.FunctionComponent<IProps> = observer(({ form }) => {
  const { app } = useStore()

  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    if (app.appState.contactFormSuccessful !== null) {
      setIsSending(false)
    }
  }, [app.appState.contactFormSuccessful])

  const { getFieldDecorator, getFieldsError, getFieldsValue } = form

  const formIsValid = (): boolean => {
    const errors = getFieldsError()
    const values = getFieldsValue()
    // No email error, no blank email.
    return (
      errors.email === undefined &&
      values.email !== '' &&
      values.email !== undefined
    )
  }

  const handleSubmit = (): void => {
    setIsSending(true)
    const { email, message } = getFieldsValue()
    void app.submitContactForm(
      sanitize(email),
      sanitize(message ?? ''),
    )
  }

  const emailForm = (
    <Form
      className="AppContactModal__Form"
      hideRequiredMark
    >
      <Form.Item label="Your Email Address::" className="AppContactModal__FormItem">
        {getFieldDecorator('email', {
          initialValue: app.userData.email ?? '',
          rules: [
            {
              type: 'email',
              message: 'Please enter a valid email address.',
            },
            {
              required: true,
              message: 'Please enter your email address.',
            },
          ],
          validateTrigger: 'onBlur',
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Message::" className="AppContactModal__FormItem">
        {getFieldDecorator('message')(
          <TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
          />,
        )}
      </Form.Item>
    </Form>
  )

  const closeFormAndReset = (): void => {
    app.setContactFormOpen(false)
    setTimeout(() => {
      app.setContactFormSuccessful(null)
    }, 500)
  }

  const modalFooter = (
    <>
      {
        app.appState.contactFormSuccessful !== true
          ? (
            <>
              <Button
                onClick={closeFormAndReset}
              >
              Cancel
              </Button>
              <Button
                disabled={!formIsValid()}
                loading={isSending}
                onClick={handleSubmit}
                type="primary"
              >
              Send
              </Button>
            </>
          )
          : (
            <Button
              onClick={closeFormAndReset}
              type="primary"
            >
            Close
            </Button>
          )
      }
    </>
  )

  const resultAlert = (success: boolean): React.ReactNode => {
    const message = success ? 'Thank You!' : 'There was a problem.'
    const description = success
      ? 'Your message has been sent, and we will get back to you soon.'
      : 'We couldn’t send your message. Please double check your internet ' +
      'connection and try again. If the problem persists, please email us at ' +
      'mscoutweb@mavenwe.com.'
    const type = success ? 'success' : 'warning'
    return (
      <div className="AppContactModal__alert-wrapper">
        <Alert
          message={message}
          description={description}
          type={type}
          showIcon
        />
      </div>
    )
  }

  const messageBlurb = app.result !== null
    ? 'Send us a message below and we will automatically receive a copy of ' +
    'your result.'
    : 'Send us a message and we will get back to you as soon as possible.'

  return (
    <Modal
      footer={modalFooter}
      onCancel={closeFormAndReset}
      title={'Get in touch'}
      visible={app.appState.contactFormOpen}
    >
      <div className="AppContactModal__content">
        {messageBlurb} You can also email us directly at{' '}
        <a href="mailto:mscoutweb@mavenwe.com">mscoutweb@mavenwe.com</a>.
      </div>
      {
        app.appState.contactFormSuccessful !== null &&
        resultAlert(app.appState.contactFormSuccessful)
      }
      {
        app.appState.contactFormSuccessful !== true &&
        emailForm
      }
    </Modal>
  )
})

export default Form.create({ name: 'contact' })(AppContactModal)
