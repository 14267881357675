import {
  IAnsweredQuestion,
  IAnsweredQuestionWithComments,
  IComment,
  IResult,
  ISolution,
  ISolutionComment,
  IAnswer,
} from '../../shared/types'
import {
  DisplayResultBodyElement,
  DisplayResultBodyElementType,
  IDisplayResultAnswerRecapForQuestion,
  IDisplayResultComment,
  IDisplayResultSection,
  IDisplayResultTag,
  TagType,
  TitleIcon,
  IDisplayResult,
} from '../../src/types'
import { idToNum, getAnswerString } from '../../shared/utils'
import store from '.'
import { DisplayResultCallToActionKey } from '../types'

const aboutMScout = '<sub class="m">m</sub>Scout is a water treatment options screening tool. It provides a list of possible treatment options that may be relevant to your constituent of potential concern (COPC) and incorporates some site-specific considerations. However, this is intended to be a starting point for a water treatment strategy. Design and implementation of water treatment strategies must also take into account a wide range of information for context, such as: comprehensive water chemistry, flows, and periodicity; site water management plan over time; current and target concentrations; and, overall objectives and goals for water treatment. Our Maven Team can guide you through this process. We’ve even built an in-house, complementary software tool – <sub class="m">m</sub>Guide – to simplify, facilitate, and de-risk the analysis and advising process.\n\nThis is version 2 of <sub class="m">m</sub>Scout, and we are already working on future versions to include more technologies and consider more complex information. If you have a technology or consideration you would like to see included, please contact us!'

const buildSolutionComments = (
  solutionComments: ISolutionComment[],
): IDisplayResultComment[] => {
  const displaySolutionComments: IDisplayResultComment[] = []
  solutionComments.forEach((solutionComment) => {
    const relatedQuestions: string[] = []
    let separator = ''
    solutionComment.relatedQuestions.forEach((relatedQuestion, rQIndex) => {
      relatedQuestions.push(
        `${separator}**${relatedQuestion.title}** with ` +
        `**${getAnswerString(relatedQuestion.selectedAnswer)}**`,
      )
      separator = rQIndex === solutionComment.relatedQuestions.length - 2
        ? ', and '
        : ', '
    })
    displaySolutionComments.push({
      title: solutionComment.title,
      subtitle: `Because you answered ${relatedQuestions.join('')}`,
    })
  })
  return displaySolutionComments
}

const buildSolutionTags = (solution: ISolution): IDisplayResultTag[] => {
  const solutionTags: IDisplayResultTag[] = []

  solutionTags.push({
    title: solution.treatment.treatmentType,
    type: TagType.TreatmentType,
  })
  solutionTags.push({
    title: solution.treatment.treatmentClass,
    type: TagType.TreatmentClass,
  })
  if (solution.transferOrTransformation !== null) {
    solutionTags.push({
      title: solution.transferOrTransformation,
      type: TagType.TransferOrTransformation,
    })
  }

  return solutionTags
}

export interface IMergedAnswerComment extends IComment {
  relatedQuestions: IAnsweredQuestion[]
}

export interface IMergedAnswerCommentsByCId {
  [key: number]: IMergedAnswerComment
}

export interface IAnswerCommentWithMergedRelatedAnswers extends IComment {
  relatedAnswers: IAnswer[]
}

const buildAnswerComments = (
  answeredQuestionsWithComments: IAnsweredQuestionWithComments[],
): IDisplayResultComment[] => {
  // Answer comments come back from the server as organized by question.
  // Sometimes two questions have the same answer comment. We need to merge
  // these comments so they only display once.
  const mergedAnswerCommentsByCId: IMergedAnswerCommentsByCId = {}
  // const answerCommentsMerged: IAnswerCommentWithMergedRelatedAnswers[] = [];
  answeredQuestionsWithComments.forEach((question) => {
    const answerComments = question.answerComments
    // qId 0 is COPC (included in top header). Do not include here.
    if (question.qId !== 0 && answerComments !== null) {
      answerComments.forEach((answerComment) => {
        const cId = answerComment.cId
        if (mergedAnswerCommentsByCId[cId] === undefined) {
          mergedAnswerCommentsByCId[cId] = {
            cId: answerComment.cId,
            title: answerComment.title,
            relatedQuestions: [question],
          }
        } else {
          mergedAnswerCommentsByCId[cId].relatedQuestions.push(question)
        }
      })
    }
  })

  const displayAnswerComments: IDisplayResultComment[] = []
  Object.values(mergedAnswerCommentsByCId).forEach((
    mergedAnswerComment: IMergedAnswerComment,
  ) => {
    const relatedQuestions: string[] = []
    let separator = ''
    mergedAnswerComment.relatedQuestions.forEach((relatedQuestion, rQIndex) => {
      relatedQuestions.push(
        `${separator}**${relatedQuestion.title}** with ` +
        `**${getAnswerString(relatedQuestion.selectedAnswer)}**`,
      )
      separator = rQIndex === mergedAnswerComment.relatedQuestions.length - 2
        ? ', and '
        : ', '
    })
    displayAnswerComments.push({
      title: mergedAnswerComment.title,
      subtitle: `Because you answered ${relatedQuestions.join('')}`,
    })
  })

  return displayAnswerComments
}

export const buildDisplayResult = (result: IResult): IDisplayResult => {
  const displayResultSections: IDisplayResultSection[] = []

  const introAnswerComments: IDisplayResultComment[] = []

  result.answeredQuestionsWithComments[0].answerComments?.forEach((comment) => {
    introAnswerComments.push({
      title: comment.title,
    })
  })

  const introSection: IDisplayResultSection = {
    heading: {
      title: `Treating ${result.answeredQuestionsWithComments[0].selectedAnswer?.title}`,
      isIntro: true,
      resultId: result.resultId,
      secretId: result.secretId,
      comments: introAnswerComments,
    },
    body: [{
      type: DisplayResultBodyElementType.Card,
      contents: {
        collapsible: true,
        title: 'Disclaimers & About <sub class="m">m</sub>Scout v2',
        titleIcon: TitleIcon.Info,
        paragraph: aboutMScout,
      },
    }],
  }

  const solutionsDetailsCardElements: DisplayResultBodyElement[] = []

  result.solutions.forEach((solution) => {
    const solutionComments = buildSolutionComments(solution.solutionComments)
    const solutionTags = buildSolutionTags(solution)
    solutionsDetailsCardElements.push({
      type: DisplayResultBodyElementType.Card,
      contents: {
        collapsible: true,
        comments: solutionComments,
        commentsTitle: 'Things to consider:',
        paragraph: solution.treatment.comment.title,
        tags: solutionTags,
        title: solution.treatment.title,
        titleIcon: TitleIcon.Solution,
      },
    })
  })

  const solutionsSection: IDisplayResultSection = {
    heading: {
      title: 'Potential Technologies:',
    },
    body: solutionsDetailsCardElements,
  }

  let answerCommentsSection: IDisplayResultSection | null = null
  if (store.app.nonCOPCQuestionHasAnswerComments) {
    answerCommentsSection = {
      heading: {
        title: 'Things to consider:',
      },
      body: [{
        type: DisplayResultBodyElementType.Card,
        contents: {
          comments: buildAnswerComments(result.answeredQuestionsWithComments),
        },
      }],
    }
  }

  const answerRecap: IDisplayResultAnswerRecapForQuestion[] = []
  result.answeredQuestionsWithComments.forEach((question) => {
    answerRecap.push({
      qId: question.qId,
      qNum: idToNum(question.qId),
      qTitle: question.title,
      aTitle: getAnswerString(question.selectedAnswer),
    })
  })

  const answersSection: IDisplayResultSection = {
    heading: {
      title: 'You Answered:',
    },
    body: [{
      type: DisplayResultBodyElementType.AnswerRecap,
      contents: {
        answerRecap,
      },
    }],
  }

  const contactCallToAction: IDisplayResultSection = {
    body: [{
      type: DisplayResultBodyElementType.CallToAction,
      contents: {
        buttonIcon: 'mail',
        buttonTitle: 'Get in touch',
        buttonActionKey: DisplayResultCallToActionKey.OpenContactForm,
        title: 'Need more information? Want professional advice?',
      },
    }],
  }

  displayResultSections.push(introSection)
  displayResultSections.push(solutionsSection)
  if (answerCommentsSection !== null) {
    displayResultSections.push(answerCommentsSection)
  }
  displayResultSections.push(answersSection)
  displayResultSections.push(contactCallToAction)

  const displayResult = {
    date: result.date,
    version: result.version,
    sections: displayResultSections,
  }
  return displayResult
}
