import React, { useState } from 'react'

import { Input, Modal } from 'antd'
import { Col } from 'react-flexbox-grid'

import { idToNum } from '../shared/utils'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const { confirm } = Modal

interface IProps {
  aId: number
  qId: number
}

const AppAnswer: React.FunctionComponent<IProps> = observer(({ aId, qId }) => {
  const { app, router } = useStore()

  const { push } = router

  const [textInputModalVisible, setTextInputModalVisible] = useState(false)

  const question = app.questions[qId]
  const answer = question.answers[aId]
  const isSelected = question.selectedAnswer?.aId === aId

  const buttonClass = `AppAnswer__button${isSelected ? ' is-active' : ''}`

  const submitAnswer = (textInputFromUser: string | null = null): void => {
    setTextInputModalVisible(false)

    app.selectAnswer(question.qId, answer.aId)

    // Push to next question if user is going in order
    if (
      app.allPreviousQuestionsAnswered &&
      app.noUpcomingQuestionsAnswered
    ) {
      const nextQId = app.nextQId
      if (nextQId !== false) { push(`/q/${idToNum(nextQId)}`) }
    }

    const confirmTitle = app.result === null
      ? 'You’ve answered all the questions!'
      : 'Your change has been recorded.'

    // Pop modal if all questions are complete and
    // user has not previously dismissed the modal
    if (
      app.allQuestionsAnswered &&
      !app.appState.completeModalDismissed
    ) {
      confirm({
        cancelText: 'Go Back',
        className: 'AppAnswer__submit-modal',
        content: 'Are you ready to submit?',
        icon: 'check-circle',
        okText: 'Submit',
        title: confirmTitle,
        onOk () {
          app.completeScreening()
        },
        onCancel () {
          app.dismissCompleteModal()
        },
      })
    }
  }

  const handleAnswerClick = (): void => {
    if (answer.acceptsTextInput === true) {
      setTextInputModalVisible(true)
    } else if (!isSelected) {
      submitAnswer()
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    app.setTextInputForUserForAnswer(qId, aId, event.target.value)
  }

  return (
    <Col
      className="AppAnswer"
      xs={6}
      sm={4}
      md={4}
      lg={3}
    >
      <button
        className={buttonClass}
        onClick={() => handleAnswerClick()}
      >
        <span className="AppAnswer__button__title">{answer.title}</span>
      </button>
      {
        answer.acceptsTextInput === true &&
        <Modal
          title={`Enter ${question.title}`}
          visible={textInputModalVisible}
          onOk={() => submitAnswer()}
          onCancel={() => setTextInputModalVisible(false)}
          okText="Continue"
          cancelText="Cancel"
        >
          <Input
            onChange={(event) => handleInputChange(event)}
            placeholder={question.title}
            value={answer.textInputFromUser ?? ''}
          />
        </Modal>
      }
    </Col>
  )
})

export default AppAnswer
