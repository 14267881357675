import 'firebase/analytics'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

import config, { Stage } from './config'

const firebaseApp = firebase.initializeApp({
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseUrl,
  projectId: config.firebase.projectId,
  appId: config.firebase.appId,
  measurementId: config.firebase.measurementId,
})

const db = firebase.firestore()

const analytics = firebase.analytics()

const timestamp = firebase.firestore.FieldValue.serverTimestamp()

const functions = firebase.functions()

if (config.stage === Stage.DevEmulated) {
  // tslint:disable-next-line:no-console
  console.log('Using Emulated Functions.')
  functions.useFunctionsEmulator('http://localhost:5001')
}

export {
  analytics, firebase, firebaseApp, timestamp, db, functions,
}
