import { IQuestion } from './types'

const initialQuestions: IQuestion[] = [
  {
    answers: [
      {
        aId: 0,
        title: 'Acidity',
      },
      {
        aId: 1,
        title: 'Aluminum',
      },
      {
        aId: 2,
        title: 'Arsenic',
      },
      {
        aId: 3,
        title: 'Cadmium',
      },
      {
        aId: 4,
        title: 'Cobalt',
      },
      {
        aId: 5,
        title: 'Cyanide',
      },
      {
        aId: 6,
        title: 'Copper',
      },
      {
        aId: 7,
        title: 'Chromium',
      },
      {
        aId: 8,
        title: 'Iron',
      },
      {
        aId: 9,
        title: 'Mercury',
      },
      {
        aId: 10,
        title: 'Manganese',
      },
      {
        aId: 11,
        title: 'Molybdenum',
      },
      {
        aId: 12,
        title: 'Naphthenic Acids',
      },
      {
        aId: 13,
        title: 'Ammonia',
      },
      {
        aId: 14,
        title: 'Nickel',
      },
      {
        aId: 15,
        title: 'Nitrate and Nitrite',
      },
      {
        aId: 16,
        title: 'Lead',
      },
      {
        aId: 17,
        title: 'Antimony',
      },
      {
        aId: 18,
        title: 'Thiocyanate',
      },
      {
        aId: 19,
        title: 'Selenium',
      },
      {
        aId: 20,
        title: 'Sulphate',
      },
      {
        aId: 21,
        title: 'Uranium',
      },
      {
        aId: 22,
        title: 'Vanadium',
      },
      {
        aId: 23,
        title: 'Zinc',
      },
    ],
    categories: [
      // Naphthenic Acids excluded at Maven's request
      {
        title: 'Metals & Metalloids',
        aIds: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 14, 16, 17, 19, 21, 22, 23],
      },
      {
        title: 'Organics, Nutrients, and Others',
        aIds: [0, 5, 13, 15, 18, 20],
      },
    ],
    qId: 0,
    selectedAnswer: null,
    subtitle: 'Choose one.',
    title: 'Which Constituent of Potential Concern do you want to treat?',
  },
  {
    answers: [
      {
        aId: 0,
        title: 'Operations',
      },
      {
        aId: 1,
        title: 'Closure',
      },
      {
        aId: 2,
        title: 'Both',
      },
    ],
    qId: 1,
    selectedAnswer: null,
    subtitle: null,
    title: 'Treatment is needed in:',
  },
  {
    answers: [
      {
        aId: 0,
        title: 'Recycle to Process',
      },
      {
        aId: 1,
        title: 'Discharged to Surface',
      },
      {
        aId: 2,
        acceptsTextInput: true,
        textInputFromUser: '',
        title: 'Other...',
      },
    ],
    qId: 2,
    selectedAnswer: null,
    subtitle: null,
    title: 'Desired End Use of Water',
  },
  {
    answers: [
      {
        aId: 0,
        title: '<1 year',
      },
      {
        aId: 1,
        title: '1-10 years',
      },
      {
        aId: 2,
        title: '>10 years',
      },
    ],
    qId: 3,
    selectedAnswer: null,
    subtitle: null,
    title: 'Targeted Lifespan of Treatment System',
  },
  {
    answers: [
      {
        aId: 0,
        title: '<3 months',
      },
      {
        aId: 1,
        title: '<1 year',
      },
      {
        aId: 2,
        title: '>1 year',
      },
    ],
    qId: 4,
    selectedAnswer: null,
    subtitle: null,
    title: 'Lead time before system operation needed',
  },
  {
    answers: [
      {
        aId: 0,
        title: '<1 ha',
      },
      {
        aId: 1,
        title: '1-5 ha',
      },
      {
        aId: 2,
        title: '>5 ha',
      },
    ],
    qId: 5,
    selectedAnswer: null,
    subtitle: null,
    title: 'Constructable Land Area Available',
  },
  {
    answers: [
      {
        aId: 0,
        title: 'Temperate (does not freeze)',
      },
      {
        aId: 1,
        title: 'Seasonally Cold (freezes part of year)',
      },
      {
        aId: 2,
        title: 'Hot/Arid (does not freeze)',
      },
    ],
    qId: 6,
    selectedAnswer: null,
    subtitle: null,
    title: 'Climate',
  },
  {
    answers: [
      {
        aId: 0,
        title: 'Year Round',
      },
      {
        aId: 1,
        title: 'Summer Only',
      },
      {
        aId: 2,
        title: 'Intermittent',
      },
    ],
    qId: 7,
    selectedAnswer: null,
    subtitle: null,
    title: 'Periodicity',
  },
  {
    answers: [
      {
        aId: 0,
        title: 'Surface (e.g., contact water, process water)',
      },
      {
        aId: 1,
        title: 'Pit Lake',
      },
      {
        aId: 2,
        title: 'Tailings Pond',
      },
      {
        aId: 3,
        title: 'Underground Mine Workings (flooded)',
      },
      {
        aId: 4,
        title: 'Underground Mine Workings (not flooded)',
      },
      {
        aId: 5,
        title: 'Saturated Rock Fill',
      },
      {
        aId: 6,
        title: 'Other',
      },
    ],
    qId: 8,
    selectedAnswer: null,
    subtitle: null,
    title: 'Water Location/Source',
  },
]

export default initialQuestions
