import React, { useState, useEffect } from 'react'
import { FormComponentProps } from '../node_modules/antd/lib/form/Form'
import { Alert, Button, Form, Input, Modal, Checkbox } from 'antd'

import { useStore } from './store'
import { sanitize } from '../shared/utils'
import { observer } from 'mobx-react-lite'

const { TextArea } = Input

interface IProps extends FormComponentProps {
  closeModal: () => void
  visible: boolean
}

const AppEmailShareModal: React.FunctionComponent<IProps> = observer(({
  closeModal,
  form,
  visible,
}) => {
  const { app } = useStore()

  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    if (app.appState.emailShareSuccessful !== null) {
      setIsSending(false)
    }
  }, [app.appState.emailShareSuccessful])

  const { getFieldDecorator, getFieldsError, getFieldsValue } = form

  const formIsValid = (): boolean => {
    const errors = getFieldsError()
    const values = getFieldsValue()
    // Sender name must be filled.
    // Both email addresses must be filled with no errors.
    return (
      errors.shareSenderEmail === undefined &&
      errors.shareRecipientEmail === undefined &&
      values.shareSenderName !== '' &&
      values.shareSenderName !== undefined &&
      values.shareSenderEmail !== '' &&
      values.shareSenderEmail !== undefined &&
      values.shareRecipientEmail !== '' &&
      values.shareRecipientEmail !== undefined
    )
  }

  const handleSubmit = (): void => {
    setIsSending(true)
    const {
      shareSenderName,
      shareSenderEmail,
      shareSendACopy,
      shareRecipientEmail,
      shareMessage,
    } = getFieldsValue()

    void app.submitShareByEmail(
      sanitize(shareSenderName),
      sanitize(shareSenderEmail),
      shareSendACopy,
      sanitize(shareRecipientEmail),
      sanitize(shareMessage ?? ''),
    )
  }

  let fullName = app.userData.firstName ?? ''
  if (app.userData.lastName !== undefined) {
    fullName += ` ${app.userData.lastName}`
  }

  const shareForm = (
    <Form
      className="AppEmailShareModal__Form"
      hideRequiredMark
    >
      <Form.Item
        label="Your Name::"
        className="AppEmailShareModal__FormItem"
      >
        {getFieldDecorator('shareSenderName', {
          initialValue: fullName,
          rules: [
            {
              required: true,
              message: 'Please enter your name.',
            },
          ],
          validateTrigger: 'onBlur',
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label="Your Email Address::"
        className="AppEmailShareModal__FormItem AppEmailShareModal__FormItem--your-email"
      >
        {getFieldDecorator('shareSenderEmail', {
          initialValue: app.userData.email ?? '',
          rules: [
            {
              type: 'email',
              message: 'Please enter a valid email address.',
            },
            {
              required: true,
              message: 'Please enter your email address.',
            },
          ],
          validateTrigger: 'onBlur',
        })(<Input />)}
      </Form.Item>
      <Form.Item className="AppEmailShareModal__FormItem">
        {getFieldDecorator('shareSendACopy', {
          valuePropName: 'checked',
          initialValue: false,
        })(<Checkbox>Send me a copy of this email</Checkbox>)}
      </Form.Item>
      <Form.Item label="Recipient’s Email Address::" className="AppEmailShareModal__FormItem">
        {getFieldDecorator('shareRecipientEmail', {
          rules: [
            {
              type: 'email',
              message: 'Please enter a valid email address.',
            },
            {
              required: true,
              message: 'Please enter the recipient’s email address.',
            },
          ],
          validateTrigger: 'onBlur',
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Message: (optional)" className="AppEmailShareModal__FormItem">
        {getFieldDecorator('shareMessage')(
          <TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
          />,
        )}
      </Form.Item>
    </Form>
  )

  const closeFormAndReset = (): void => {
    closeModal()
    setTimeout(() => {
      app.setEmailShareSuccessful(null)
    }, 500)
  }

  const modalFooter = (
    <>
      {
        app.appState.emailShareSuccessful !== true
          ? (
            <>
              <Button
                onClick={closeFormAndReset}
              >
              Cancel
              </Button>
              <Button
                disabled={!formIsValid()}
                loading={isSending}
                onClick={handleSubmit}
                type="primary"
              >
              Send
              </Button>
            </>
          )
          : (
            <Button
              onClick={closeFormAndReset}
              type="primary"
            >
            Close
            </Button>
          )
      }
    </>
  )

  const resultAlert = (success: boolean): React.ReactNode => {
    const message = success ? 'Thank You!' : 'There was a problem.'
    const description = success
      ? 'Your message has been sent.'
      : 'We couldn’t send your message. Please double check your internet ' +
      'connection and try again. If the problem persists, please email us at ' +
      'mscoutweb@mavenwe.com.'
    const type = success ? 'success' : 'warning'
    return (
      <div className="AppEmailShareModal__alert-wrapper">
        <Alert
          message={message}
          description={description}
          type={type}
          showIcon
        />
      </div>
    )
  }

  return (
    <Modal
      footer={modalFooter}
      onCancel={closeFormAndReset}
      title={'Share your result by email'}
      visible={visible}
    >
      {
        app.appState.emailShareSuccessful !== null &&
        resultAlert(app.appState.emailShareSuccessful)
      }
      {
        app.appState.emailShareSuccessful !== true &&
        shareForm
      }
    </Modal>
  )
})

export default Form.create<IProps>({ name: 'email-share' })(AppEmailShareModal)
