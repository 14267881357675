import 'tslib'
import 'react-hot-loader'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import * as serviceWorker from './serviceWorker'

import store, { syncedHistory, StoreProvider } from './store'

import App from './App'

const hasTouch = (): boolean => (
  'ontouchstart' in document.documentElement ||
    navigator.maxTouchPoints > 0
)

if (!hasTouch()) {
  document.body.className += ' has-hover'
}

ReactDOM.render(
  <StoreProvider value={store}>
    <Router history={syncedHistory}>
      <App />
    </Router>
  </StoreProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
