import React, { useState, useEffect } from 'react'
import { Collapse } from 'react-collapse'

import AppResultDetails from './AppResultDetails'
import AppAlert from './AppAlert'
import { Divider } from 'antd'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const AppTopBanner: React.FunctionComponent = observer(() => {
  const { app, router } = useStore()

  const [bannerTransitionedIn, setBannerTransitionedIn] = useState(false)

  useEffect(() => {
    if (
      (
        router.location.pathname.indexOf('solutions') !== -1 &&
        app.result !== null
      ) ||
      app.appState.alert !== null
    ) {
      setTimeout(() => {
        setBannerTransitionedIn(true)
      }, 500)
    } else {
      setBannerTransitionedIn(false)
    }
  }, [
    router.location.pathname,
    app.result,
    app.appState.alert,
  ])

  const hasAlert = app.appState.alert !== null

  const isSolutionsPage = (
    router.location.pathname.indexOf('solutions') !== -1 &&
    app.result !== null
  )

  return (
    <div className="AppTopBanner">
      <div className="AppTopBanner__content">
        <Collapse
          isOpened={bannerTransitionedIn}
        >
          <div className="AppTopBanner__content-inner">
            {
              hasAlert &&
              <AppAlert
                buttonActionKey={app.appState.alert?.buttonActionKey}
                buttonTitle={app.appState.alert?.buttonTitle}
                title={app.appState.alert?.title}
                subtitle={app.appState.alert?.subtitle}
              />
            }
            {
              hasAlert && isSolutionsPage &&
              <Divider />
            }
            {
              isSolutionsPage &&
              <AppResultDetails />
            }
          </div>
        </Collapse>
      </div>
    </div>
  )
})

export default AppTopBanner
