import React from 'react'

import ReactMarkdownWithHtml from 'react-markdown/with-html'
import type ReactMarkdown from 'react-markdown'
import { Text } from '@react-pdf/renderer'

interface IProps extends ReactMarkdown.ReactMarkdownPropsBase, ReactMarkdown.SourceProp {
  allowParagraphs?: boolean
  forPDF?: boolean
}

// Be careful! Only send this component trusted markup, never anything
// user generated. It's dangerous!

const AppMarkdownWithHtml: React.FunctionComponent<IProps> = ({
  allowParagraphs,
  className,
  forPDF,
  source,
}) => {
  let disallowedTypes: ReactMarkdown.NodeType[] = allowParagraphs === true
    ? []
    : ['paragraph']

  let renderers

  if (forPDF === true) {
    disallowedTypes = []
    renderers = {
      paragraph: (props: any) => <Text {...props} />,
      strong: (props: any) => <Text style={{ fontFamily: 'Helvetica-Bold' }} {...props} />,
      emphasis: (props: any) => <Text style={{ fontFamily: 'Helvetica-Oblique' }} {...props} />,
    }
  }

  return (
    <ReactMarkdownWithHtml
      allowDangerousHtml
      className={className}
      disallowedTypes={disallowedTypes}
      renderers={renderers}
      source={source}
      unwrapDisallowed
    />
  )
}

export default AppMarkdownWithHtml
