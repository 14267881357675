// Note, as this file is shared between projects, ensure libraries below are
// installed in both.

import * as t from 'io-ts'
import { types } from 'mobx-state-tree'

// Types needed both in the app and API

// Type naming conventions:
// const Answer - io-ts runtime type
// interface IAnswer - typescript type
// const MAnswer - mst runtime type

export const Answer = t.intersection([
  t.type({
    aId: t.number,
    title: t.string,
  }),
  t.partial({
    acceptsTextInput: t.boolean,
    textInputFromUser: t.string,
  }),
])

export interface IAnswer extends t.TypeOf<typeof Answer> { }

const MAnswer = types.model('Answer', {
  aId: types.identifierNumber,
  title: types.string,
  acceptsTextInput: types.maybe(types.boolean),
  textInputFromUser: types.maybe(types.string),
})

export const Comment = t.type({
  cId: t.number,
  title: t.string,
})

export interface IComment extends t.TypeOf<typeof Comment> { }

const MComment = types.model('Comment', {
  cId: types.number,
  title: types.string,
})

export const AnswerComment = t.intersection([
  Comment,
  t.type({
    relatedAnswer: Answer,
  }),
])

export interface IAnswerComment extends t.TypeOf<typeof AnswerComment> { }

const MAnswerComment = types.compose('AnswerComment',
  MComment,
  types.model({
    relatedAnswer: MAnswer,
  }),
)

const QuestionCategory = t.type({
  title: t.string,
  aIds: t.array(t.number),
})

const MQuestionCategory = types.model('QuestionCategory', {
  title: types.string,
  aIds: types.array(types.number),
})

const QuestionBase = t.intersection([
  t.type({
    answers: t.array(Answer),
    qId: t.number,
    subtitle: t.union([t.string, t.null]),
    title: t.string,
  }),
  t.partial({
    categories: t.array(QuestionCategory),
  }),
])

const MQuestionBase = types.model({
  answers: types.array(MAnswer),
  qId: types.number,
  subtitle: types.union(types.string, types.null),
  title: types.string,
  categories: types.maybe(types.array(MQuestionCategory)),
})

export const Question = t.intersection([
  QuestionBase,
  t.type({
    selectedAnswer: t.union([Answer, t.null]),
  }),
])

export interface IQuestion extends t.TypeOf<typeof Question> { }

export const MQuestion = types.compose('Question',
  MQuestionBase,
  types.model({
    selectedAnswer: types.maybeNull(MAnswer),
  }),
)

export const AnsweredQuestion = t.intersection([
  QuestionBase,
  t.type({
    selectedAnswer: Answer,
  }),
])

export interface IAnsweredQuestion extends t.TypeOf<typeof AnsweredQuestion> { }

const MAnsweredQuestion = types.compose('AnsweredQuestion',
  MQuestionBase,
  types.model({
    selectedAnswer: MAnswer,
  }),
)

export const AnsweredQuestionWithComments = t.intersection([
  AnsweredQuestion,
  t.type({
    answerComments: t.union([
      t.array(AnswerComment),
      t.null,
    ]),
  }),
])

export interface IAnsweredQuestionWithComments extends t.TypeOf<typeof AnsweredQuestionWithComments> { }

const MAnsweredQuestionWithComments = types.compose('AnsweredQuestionWithComments',
  MAnsweredQuestion,
  types.model({
    answerComments: types.maybeNull(types.array(MAnswerComment)),
  }),
)

export const Treatment = t.type({
  comment: Comment,
  tId: t.number,
  title: t.string,
  treatmentClass: t.string,
  treatmentType: t.string,
})

export interface ITreatment extends t.TypeOf<typeof Treatment> { }

const MTreatment = types.model('MTreatment', {
  comment: MComment,
  tId: types.number,
  title: types.string,
  treatmentClass: types.string,
  treatmentType: types.string,
})

export const UserData = t.partial({
  email: t.string,
  company: t.string,
  firstName: t.string,
  lastName: t.string,
})

export interface IUserData extends t.TypeOf<typeof UserData> { }

export const MUserData = types.model('UserData', {
  email: types.maybe(types.string),
  company: types.maybe(types.string),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
})

export const FinalAnswers = t.type({
  answeredQuestions: t.array(AnsweredQuestion),
  origin: t.string,
  version: t.string,
  userData: UserData,
})

export interface IFinalAnswers extends t.TypeOf<typeof FinalAnswers> { }

export const SolutionComment = t.intersection([
  Comment,
  t.type({
    relatedQuestions: t.array(AnsweredQuestion),
  }),
])

export interface ISolutionComment extends t.TypeOf<typeof SolutionComment> { }

const MSolutionComment = types.compose('SolutionComment',
  MComment,
  types.model({
    relatedQuestions: types.array(MAnsweredQuestion),
  }),
)

export const Solution = t.type({
  solutionComments: t.array(SolutionComment),
  transferOrTransformation: t.union([t.string, t.null]),
  treatment: Treatment,
})

export interface ISolution extends t.TypeOf<typeof Solution> { }

const MSolution = types.model('Solution', {
  solutionComments: types.array(MSolutionComment),
  transferOrTransformation: types.maybeNull(types.string),
  treatment: MTreatment,
})

export const Result = t.intersection([
  t.type({
    answeredQuestionsWithComments: t.array(AnsweredQuestionWithComments),
    date: t.number,
    origin: t.string,
    resultId: t.string,
    secretId: t.string,
    solutions: t.array(Solution),
    version: t.string,
  }),
  t.partial({
    userData: UserData,
  }),
])

export interface IResult extends t.TypeOf<typeof Result> { }

// Firestore throws an error when properties are undefined. As a result, this type
// should be used when persisting new results to Firestore. Old results won't
// contain userData, so we leave userData optional in the client side. These
// two types can likely be merged into one in the future, if care is taken
// to ensure that fetching old results will still work.

export const ResultForStorage = t.type({
  answeredQuestionsWithComments: t.array(AnsweredQuestionWithComments),
  date: t.number,
  origin: t.string,
  resultId: t.string,
  secretId: t.string,
  solutions: t.array(Solution),
  version: t.string,
  userData: UserData,
})

export interface IResultForStorage extends t.TypeOf<typeof ResultForStorage> { }

export const MResult = types.model('Result', {
  answeredQuestionsWithComments: types.array(MAnsweredQuestionWithComments),
  date: types.number,
  origin: types.string,
  resultId: types.string,
  secretId: types.string,
  solutions: types.array(MSolution),
  version: types.string,
  userData: types.maybe(MUserData),
})

/* #region  API Request Params */

export const StoredResultRequest = t.type({
  resultId: t.string,
  secretId: t.string,
})

export interface IStoredResultRequest extends t.TypeOf<typeof StoredResultRequest> { }

export const ContactFormEmail = t.type({
  from: t.string,
  body: t.string,
  relatedResultId: t.union([t.string, t.undefined]),
})

export interface IContactFormEmail extends t.TypeOf<typeof ContactFormEmail> { }

export const ShareFormDetails = t.type({
  fromName: t.string,
  from: t.string,
  copyToSender: t.boolean,
  to: t.string,
  body: t.string,
  relatedResultId: t.string,
})

export interface IShareFormDetails extends t.TypeOf<typeof ShareFormDetails> { }

/* #endregion */
