import React from 'react'

import { Row } from 'react-flexbox-grid'

import AppAnswer from './AppAnswer'
import AppQuestionCategory from './AppQuestionCategory'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

interface IProps {
  qId: number
}

const AppQuestionContent: React.FunctionComponent<IProps> = observer(({ qId }) => {
  const { app } = useStore()

  const question = app.questions[qId]
  let questionContent: JSX.Element

  if (question.categories !== undefined) {
    const categories = question.categories.map((category) => (
      <AppQuestionCategory
        qId={qId}
        aIds={category.aIds}
        title={category.title}
        key={`QuestionCategory${category.title}`}
      />
    ))

    questionContent = (
      <div className="AppAnswers--categories">
        {categories}
      </div>
    )
  } else {
    const answers = question.answers.map((answer) => (
      <AppAnswer
        qId={question.qId}
        aId={answer.aId}
        key={`Answer${answer.aId}`}
      />
    ))

    questionContent = (
      <Row className="AppAnswers">
        {answers}
      </Row>
    )
  }

  return (
    <div>
      {questionContent}
    </div>
  )
})

export default AppQuestionContent
