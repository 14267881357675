import React from 'react'
import { Button, Icon, Tooltip } from 'antd'

import { idToNum } from '../shared/utils'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

const AppPrevNext: React.FunctionComponent = observer(() => {
  const { app, router } = useStore()

  const { push } = router

  const prevQId = app.prevQId
  const nextQId = app.nextQId

  const handlePrevClick = (): void => {
    if (prevQId === false) { return }
    push(`/q/${idToNum(prevQId)}`)
  }

  const handleNextOrSubmitClick = (): void => {
    if (nextQId === false) {
      if (app.allQuestionsAnswered) {
        app.completeScreening()
      }
    } else {
      push(`/q/${idToNum(nextQId)}`)
    }
  }

  const isFirstQuestion = prevQId === false
  const isLastQuestion = nextQId === false

  const nextOrSubmitEnabled = !isLastQuestion || app.allQuestionsAnswered
  let incompleteMessage = null
  if (isLastQuestion && !nextOrSubmitEnabled) {
    incompleteMessage = 'Please ensure all questions are answered.'
  }

  return (
    <>
      <Button
        className={`AppPrevNext__Button${isFirstQuestion ? ' vis-hidden' : ''}`}
        type="primary"
        size="default"
        onClick={() => handlePrevClick()}
      >
        <Icon type="left" />
        Previous
      </Button>
      <Tooltip placement="topRight" title={incompleteMessage}>
        <Button
          className="AppPrevNext__Button"
          disabled={!nextOrSubmitEnabled}
          type="primary"
          size="default"
          onClick={() => handleNextOrSubmitClick()}
        >
          {nextQId === false ? 'Submit' : 'Next'}
          <Icon type="right" />
        </Button>
      </Tooltip>
    </>
  )
})

export default AppPrevNext
