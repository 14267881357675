import React, { ReactChild } from 'react'

import { Button } from 'antd'
import { AlertActionKey } from './types'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'
import AppMarkdownWithHtml from './AppMarkdownWithHtml'

interface IProps {
  buttonActionKey?: AlertActionKey
  buttonTitle?: string
  children?: ReactChild
  subtitle?: string
  title?: string
}

const AppAlert: React.FunctionComponent<IProps> = observer(({
  buttonActionKey,
  buttonTitle,
  children,
  subtitle,
  title,
}: IProps) => {
  const { app } = useStore()

  const buttonActions = {
    [AlertActionKey.CompleteScreening] () {
      app.completeScreening()
    },
    [AlertActionKey.ReloadSite] () {
      window.location.href = '/'
    },
  }

  const handleButtonClick = (): void => {
    if (buttonActionKey !== undefined) {
      buttonActions[buttonActionKey]()
    }
  }

  return (
    <div className="AppAlert">
      <div className="AppAlert__content">
        {
          title !== undefined &&
          <div className="AppAlert__section">
            <div className="AppAlert__text">
              <div className="AppAlert__text__title">{title}</div>
              {
                subtitle !== undefined &&
                <div className="AppAlert__text__sub">
                  <AppMarkdownWithHtml source={subtitle} />
                </div>
              }
            </div>
            <div className="AppAlert__btn-container">
              <Button
                className="AppAlert__Button"
                onClick={handleButtonClick}
                type="primary"
              >
                {buttonTitle}
              </Button>
            </div>
          </div>
        }
        {children}
      </div>
    </div>
  )
})

export default AppAlert
