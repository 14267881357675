// Note, as this file is shared between projects, ensure libraries below are
// installed in both.

import * as t from 'io-ts'
import { IAnswer } from './types'
import { fold, isRight } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import { PathReporter } from 'io-ts/lib/PathReporter'

export const idToNum = (id: string | number): number => {
  return Number(id) + 1
}

export const numToId = (num: string | number): number => {
  return Number(num) - 1
}

export const getAnswerString = (answer: IAnswer): string => {
  let str = answer.title
  if (
    answer.textInputFromUser !== undefined &&
    answer.textInputFromUser !== ''
  ) {
    str += ` (${answer.textInputFromUser})`
  }
  return str
}

export const sanitize = (str: string): string => {
  const map: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  }
  const reg = /[&<>"'/]/ig
  return str.replace(reg, (match) => (map[match]))
}

export const stringLooksClean = (str: string): boolean => {
  const regex = RegExp(/[<>"'/]/)
  return !(regex.test(str))
}

export const stringsInObjectLookClean = (obj: object): boolean => {
  for (const value of Object.values(obj)) {
    if (typeof value === 'string' && !stringLooksClean(value)) {
      return false
    }
  }
  return true
}

export const addBrTags = (str: string): string => {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

// https://github.com/gcanti/io-ts/blob/master/index.md#error-reporters
export const getErrorPaths = <A>(v: t.Validation<A>): string[] => {
  return pipe(
    v,
    fold(
      (errors) => {
        return errors.map((error) => error.context.map(({ key }) => key).join('.'))
      },
      () => ['no errors'],
    ),
  )
}

// Prep for API steps

// 1. Make a deep clone with JSON.parse(JSON.stringify(...)) - The purpose of
//    this is is twofold:
//      a) This removes undefined values. Mobx State Tree does not
//         support optional properties, so before sending to the API, we need to
//         remove undefined values recursively from objects, because they end up
//         converted to null by firebase functions and don't pass validation.
//      b) Using a MobX object with io-ts seems to fail validation for some
//         reason. It can be converted to a snapshot with getSnapshot(...),
//         but the JSON trick seems to suffice on its own.
// 3. Run the value through the io-ts decoder with the appropriate type, to
//    ensure that it will be valid when the API runs its own matching validation.

export const prepForApi = <T>(
  obj: unknown,
  iotsType: t.Any,
): T => {
  const nodeWithoutUndefined = JSON.parse(JSON.stringify(obj))
  const decoded = iotsType.decode(nodeWithoutUndefined)

  if (isRight(decoded)) {
    return decoded.right
  } else {
    let error = PathReporter.report(decoded).toString()
    error += '\n\n' + getErrorPaths(decoded).join()
    throw new Error(error)
  }
}
