import React, { useState } from 'react'
import { Typography, Icon } from 'antd'
import { Row } from 'react-flexbox-grid'
import { Collapse } from 'react-collapse'

import AppAnswer from './AppAnswer'

const { Title } = Typography

interface IProps {
  aIds: number[]
  qId: number
  title: string
}

const AppQuestionCategory: React.FunctionComponent<IProps> = ({
  aIds,
  qId,
  title,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const collapsedClass = isOpened ? ' is-open' : ''

  return (
    <>
      <div className="AppQuestionCategory__header" onClick={() => setIsOpened(!isOpened)}>
        <Title className="AppQuestionCategory__header-title" level={3}>
          {title}
        </Title>
        <Icon
          className={
            `AppQuestionCategory__arrow${collapsedClass}`
          }
          type="down"
        />
      </div>
      <div>
        <Collapse isOpened={isOpened}>
          <Row className="AppQuestionCategory__answers">
            {aIds.map((aId) => (
              <AppAnswer
                qId={qId}
                aId={aId}
                key={`Answer${aId}`}
              />
            ))}
          </Row>
        </Collapse>
      </div>
    </>
  )
}

export default AppQuestionCategory
