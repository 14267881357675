import React, { Component } from 'react'

import { Button, Input, message, Tooltip } from 'antd'
import { InputProps, InputState } from '../node_modules/antd/lib/input/Input'

import CopyToClipboard from 'react-copy-to-clipboard'

const copyButton = (value: string): React.ReactNode => (
  <CopyToClipboard
    onCopy={() => message.success('Link copied.')}
    text={value}
  >
    <Tooltip title="Copy link">
      <Button
        className="AppCopyInput__Button"
        icon="copy"
        type="link"
      />
    </Tooltip>
  </CopyToClipboard>
)

interface IProps extends InputProps {
  value: string
}

export default class AppCopyInput extends Component<IProps, InputState> {
  urlInput: React.RefObject<Input>

  constructor (props: IProps) {
    super(props)
    this.urlInput = React.createRef()
  }

  render (): React.ReactNode {
    return (
      <Input
        addonAfter={copyButton(this.props.value)}
        className="AppCopyInput"
        onFocus={() => {
          // setTimeout as something causes the text to be deselected
          // immediately otherwise
          setTimeout(() => {
            this.urlInput.current?.select()
          }, 0)
        }}
        ref={this.urlInput}
        value={this.props.value}
      />
    )
  }
}
