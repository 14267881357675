import React from 'react'

import { Modal, Spin } from 'antd'
import $ from 'jquery'
import HubspotForm from 'react-hubspot-form'

import { IUserData } from '../shared/types'
import { useStore } from './store'
import { observer } from 'mobx-react-lite'

enum HubspotDataPointName {
  email = 'email',
  firstname = 'firstname',
  lastname = 'lastname',
  company = 'company',
}

interface IDataPointByHubspotName extends Record<
HubspotDataPointName,
keyof IUserData
> { }

const dataPointByHubspotName: IDataPointByHubspotName = {
  email: 'email',
  firstname: 'firstName',
  lastname: 'lastName',
  company: 'company',
}

interface IFormDataPoint {
  name: string
  value: string
}

const AppEmailResultModal: React.FunctionComponent = observer(() => {
  window.jQuery = $
  window.$ = $

  const { app } = useStore()

  const visible = app.appState.emailResultModalOpen

  const closeModal = (): void => {
    app.setEmailResultModalOpen(false)
  }

  const handleCancel = (): void => {
    closeModal()
    app.setReadyWhenYouAreAlert()
  }

  const handleSubmit = (formData: IFormDataPoint[]): void => {
    closeModal()

    const userData: IUserData = {}
    formData.forEach((dataPoint) => {
      if (
        dataPoint.value !== '' &&
        Object.keys(dataPointByHubspotName).includes(dataPoint.name)
      ) {
        const hubspotKey = dataPoint.name as HubspotDataPointName
        const userDataKey = dataPointByHubspotName[hubspotKey]
        userData[userDataKey] = dataPoint.value
      }
      app.setUserData(userData)
    })

    void app.submitAnswers()
  }

  const completionMessage = 'Redirecting you to your results! If this takes ' +
   'more than a second, something went wrong. Please let us know at ' +
   'mscoutweb@mavenwe.com.'

  return (
    <Modal
      title={'Where should we send your results?'}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="AppEmailResultModal__info">
        Enter your email address if you would like your results sent to you, or
        click <i>Continue</i> to view them online only.
      </div>
      <HubspotForm
        portalId="6890066"
        formId="cd4b3d05-16ec-40cc-98d8-863bfbe0f93e"
        loading={<Spin className="AppEmailResultModal__Spin" />}
        inlineMessage={completionMessage}
        onSubmit={handleSubmit}
        translations={{ en: { submitText: 'Continue' } }}
      />
    </Modal>
  )

  // Previously proposed custom form.
  // const { getFieldDecorator, getFieldsError, getFieldsValue } = form
  // const formIsValid = (): boolean => {
  //   const fieldsError = getFieldsError()
  //   const fieldsValue = getFieldsValue()
  //   // No email error, no blank email
  //   return !(
  //     fieldsError.email !== undefined ||
  //     fieldsValue.email === ''
  //   )
  // }
  // const modalFooter = (
  //   <>
  //     <Button
  //       onClick={closeModal}
  //     >
  //       Cancel
  //     </Button>
  //     <Button
  //       disabled={!formIsValid()}
  //       onClick={closeModal}
  //       type="primary"
  //     >
  //       Continue
  //     </Button>
  //   </>
  // )
  // return (
  //   <Modal
  //     footer={modalFooter}
  //     onCancel={closeModal}
  //     title={'Where should we send your results?'}
  //     visible={visible}
  //   >
  //     <Form
  //       className="AppEmailResultModal__Form"
  //     >
  //       <Form.Item
  //         label="Your Email Address::"
  //         className="AppEmailResultModal__FormItem"
  //       >
  //         {getFieldDecorator('email', {
  //           rules: [
  //             {
  //               type: 'email',
  //               message: 'Please enter a valid email address.',
  //             },
  //             {
  //               required: true,
  //               message: 'Please enter your email address.',
  //             },
  //           ],
  //           validateTrigger: 'onBlur',
  //         })(<Input />)}
  //       </Form.Item>
  //       <Form.Item
  //         label="Name::"
  //         className="AppEmailResultModal__FormItem AppEmailResultModal__FormItem--multi"
  //       >
  //         <Form.Item className="AppEmailResultModal__first-name-item">
  //           {getFieldDecorator('firstName')(<Input placeholder="First Name" />)}
  //         </Form.Item>
  //         <Form.Item className="AppEmailResultModal__last-name-item">
  //           {getFieldDecorator('lastName')(<Input placeholder="Last Name" />)}
  //         </Form.Item>
  //       </Form.Item>
  //       <Form.Item
  //         label="Company::"
  //         className="AppEmailResultModal__FormItem"
  //       >
  //         {getFieldDecorator('company')(<Input />)}
  //       </Form.Item>
  //     </Form>
  //   </Modal>
  // )
})

// export default Form.create({ name: 'emailResult' })(AppEmailResultModal)
export default AppEmailResultModal
