import { types } from 'mobx-state-tree'
import { RouterModel } from 'mst-react-router'
import { numToId } from '../../shared/utils'

const RouterStore = types
  .compose('RouterStore',
    RouterModel,
    types.model({
      initialPathNameObserved: false,
    }),
  )
  .views(self => ({
    get currentQId () {
      let routeQNum = 1
      if ((self.location.pathname as string).includes('/q/')) {
        routeQNum = Number(self.location.pathname.replace('/q/', ''))
      }
      return numToId(routeQNum)
    },
    get isShared (): boolean {
      return self.location.search.includes(('share'))
    },
  }))
  .actions(self => {
    const markInitialPathNameObserved = (): void => {
      self.initialPathNameObserved = true
    }

    return {
      markInitialPathNameObserved,
    }
  })

export default RouterStore
